import React, { useState, useEffect } from "react";
import { FaEdit, FaCheck } from "react-icons/fa";
import axios from "axios";

const ManageLeaves = () => {
  const [leaves, setLeaves] = useState([]);
  const [statusToUpdate, setStatusToUpdate] = useState(null); // Track leave status being updated
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    loadLeaves();
  }, []);

  const loadLeaves = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }

      const response = await axios.get(`http://127.0.0.1:5000/leaves/admin_or_reported`, {
        params: { user_id: userId },
      });

      setLeaves(response.data);
    } catch (error) {
      console.error("Failed to load leaves", error);
    }
  };

  const handleStatusUpdate = async (leaveId, newStatus) => {
    try {
      const response = await axios.patch(`http://127.0.0.1:5000/leaves/${leaveId}/status`, {
        status: newStatus,
      });
      console.log("Leave status updated successfully", response.data);
      setStatusToUpdate(null); // Reset status
      loadLeaves(); // Reload leaves after update
    } catch (error) {
      console.error("Error updating leave status", error);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Manage Leaves</h1>

      {leaves.length > 0 ? (
        <table style={styles.table}>
          <thead>
            <tr>
              {["Leave Type", "Reason", "User", "Reporting To", "Start Date", "End Date", "Status", "Actions"].map(
                (header) => (
                  <th key={header} style={styles.th}>
                    {header}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {leaves.map((leave, index) => (
              <tr key={index}>
                <td style={styles.td}>{leave.leave_type}</td>
                <td style={styles.td}>{leave.reason}</td>
                <td style={styles.td}>{leave.user?.name || "NA"}</td>
                <td style={styles.td}>{leave.user?.reporting_to?.name || "NA"}</td>
                <td style={styles.td}>{new Date(leave.start_date).toLocaleDateString()}</td>
                <td style={styles.td}>{new Date(leave.end_date).toLocaleDateString()}</td>
                <td style={styles.td}>
                  {statusToUpdate === leave.id ? (
                    <select
                      value={leave.status}
                      onChange={(e) => handleStatusUpdate(leave.id, e.target.value)}
                      style={styles.statusSelect}
                    >
                      {["Pending", "Approved", "Rejected", "On Hold"].map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <span>{leave.status}</span>
                  )}
                </td>
                <td style={styles.td}>
                  <button
                    style={styles.editButton}
                    onClick={() => setStatusToUpdate(statusToUpdate === leave.id ? null : leave.id)}
                  >
                    {statusToUpdate === leave.id ? <FaCheck /> : <FaEdit />} {statusToUpdate === leave.id ? "Save" : "Edit"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div style={styles.noLeavesContainer}>
          <h3>No leaves available</h3>
          <p>No leaves to manage at the moment.</p>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    color: "#2c2e34",
    marginBottom: "12px",
  },
  table: {
    margin: "0 auto",
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f3f2f2",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  editButton: {
    backgroundColor: "#4CAF50",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "8px 12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
  },
  statusSelect: {
    padding: "8px",
    fontSize: "14px",
  },
  noLeavesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 0",
    color: "#555",
    textAlign: "center",
  },
};

export default ManageLeaves;
