import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ComplianceNavbar from '../components/Navbar/ComplianceNavBar';
import axios from 'axios';
import { FaSearch, FaEllipsisV, FaTimes } from "react-icons/fa";

function MonthlyCompliancePage() {
  // Extract complianceType from the URL parameters
  const { complianceType } = useParams();

  const [taskData, setTaskData] = useState({
    title: "",
    desc: "",
    deadline: "",
    assignedTo: "",
    client: "",
  });
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const [allTeam, setallTeam] = useState([]);

  const [team, setTeam] = useState([]);

  // States for dropdowns
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [complianceClients, setComplianceClients] = useState([]);
  const user = JSON.parse(sessionStorage.getItem('user'));

  // Utility function to get the compliance name (label)
  const getComplianceName = (complianceType) => {
    switch (complianceType) {
      case 'tds-payment':
        return 'TDS Payment';
      case 'gst-r1':
        return 'GST R1';
      case 'gst-3b':
        return 'GST 3B';
      case 'pt-salaries':
        return 'Professional Tax (PT) on Salaries';
      case 'pf-esi-payment':
        return 'Provident Fund (PF) & ESI Returns Payment';
      case 'monthly-income-statement':
        return 'Monthly Income Statement';
      default:
        return 'Compliance Not Found';
    }
  };
// Utility function to get the deadline based on compliance name
const getComplianceDeadline = (complianceName) => {
    switch (complianceName) {
      case 'TDS Payment':
        return new Date(selectedYear, monthOptions.indexOf(selectedMonth), 6).toISOString().split('T')[0];
      case 'GST R1':
        return new Date(selectedYear, monthOptions.indexOf(selectedMonth), 9).toISOString().split('T')[0];
      case 'GST 3B':
        return new Date(selectedYear, monthOptions.indexOf(selectedMonth), 18).toISOString().split('T')[0];
      case 'Professional Tax (PT) on Salaries':
        return new Date(selectedYear, monthOptions.indexOf(selectedMonth), 10).toISOString().split('T')[0];
      case 'Provident Fund (PF) & ESI Returns Payment':
        return new Date(selectedYear, monthOptions.indexOf(selectedMonth), 14).toISOString().split('T')[0];
      case 'Monthly Income Statement':
        return new Date(selectedYear, monthOptions.indexOf(selectedMonth), 10).toISOString().split('T')[0];
      default:
        return '';
    }
  };

  const loadTeamMembers = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }
      const response = await axios.get(`https://backend.promptitude.deloai.com/user/users?user_id=${userId}`);
      setTeam(response.data);
    } catch (error) {
      console.error("Failed to load team members", error);
    }
  };
  // Get the compliance name using the utility function
  const complianceName = getComplianceName(complianceType);

  // Generate year options from 2024 to the next 15 years
  const yearOptions = Array.from({ length: 15 }, (_, i) => 2024 + i);

  // Month options
  const monthOptions = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Fetch clients based on compliance type and name
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://backend.promptitude.deloai.com/clients/compliance', {
          params: {
            compliance_type: 'monthly',
            compliance_name: complianceName,
          },
        });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };


    if (complianceName !== 'Compliance Not Found') {
      fetchClients();
      loadTeamMembers();
      loadAllMembers();

    }
  }, [complianceName]);


  const handleAddTask = async () => {
    try {
      console.log(taskData);
      console.log(complianceName)
      taskData.assigned_by=user.id;
      taskData.compliance_type ='monthly';
      taskData.compliance_name = complianceName;
      taskData.year = selectedYear
      taskData.month = selectedMonth;
      taskData.client_id=taskData.client;
      taskData.user_id = user.id

      if(!taskData.title ){
        alert("Title is required");
        return;
      }
      if(!taskData.deadline){
        alert("Please add Deadline for the Task");
        return;
      }
      if(!taskData.assignedTo){
        alert("Task should be assigned to an user");
        return;
      }
      
     console.log(taskData)
     
      const response = await axios.post("https://backend.promptitude.deloai.com/compliance/task", taskData);
      console.log("Task added successfully", response.data);
      setAddModalOpen(false);
      // Reset task data after successful addition
      setTaskData({ title: "", desc: "", deadline: "", assignedTo: "", reportedTo: "", client: "" });
      fetchClientsData(
         
      );  
        } catch (error) {
      console.error("Error adding task", error);
    }
  };
  const loadAllMembers = async () => {
    try {
      
      const response = await axios.get(`https://backend.promptitude.deloai.com/user/users`);
      setallTeam(response.data);
    } catch (error) {
      console.error("Failed to load team members", error);
    }
  };
// Function to handle setting the Add Modal
const handleAddModal = (isOpen, client) => {
    setTaskData({
        ...taskData,
        
        title:`${complianceName}`,
        desc: `File ${complianceName} for client ${client.name} of Year ${selectedYear} and month ${selectedMonth}`, // Assuming 'client' has a 'name' property
   client:client.id,
   deadline: getComplianceDeadline(complianceName), // Set deadline automatically

    });
    setAddModalOpen(isOpen)
};

  const handleCompleteCompliance = async (clientId) => {
    try {
      const response = await axios.post('https://backend.promptitude.deloai.com/compliance', {
        compliance_type: 'monthly',
        compliance_name: complianceName,
        year: selectedYear,
        month: selectedMonth,
        client_id: clientId,
        user_id:user.id
      });
      
      if (response.status === 200) {
        alert('Compliance marked as complete successfully');
        // Refresh complianceClients state to reflect the change
        fetchClientsData(
         
          );      }
    } catch (error) {
      console.error('Error marking compliance as complete:', error);
      alert('Failed to mark compliance as complete');
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    setTaskData({ ...taskData, [name]: value });
  };

  // Action to assign a task to a user
  const handleAssignTask = (clientId) => {
    console.log(`Assign compliance task to a user for client with ID: ${clientId}`);
    // Implement functionality here to assign the task
  };
// Frontend fetch function
async function fetchComplianceClients(complianceType, complianceName, year, quarter = null, month = null) {
    try {
        const params = new URLSearchParams({
            compliance_type: complianceType,
            compliance_name: complianceName,
            year: year
        });
        if (quarter) params.append('quarter', quarter);
        if (month) params.append('month', month);

        const response = await fetch(`https://backend.promptitude.deloai.com/compliance/clients?${params.toString()}`, {
            method: 'GET'
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch clients');
        }
        const data = await response.json();
        return data.clients;
    } catch (error) {
        console.error('Error fetching clients:', error);
        throw error;
    }
}
const handlePendingCompliance = async (clientId) => {
    try {
      const response = await axios.delete('https://backend.promptitude.deloai.com/compliance/client', {
        data: {
          compliance_type: 'monthly',
          compliance_name: complianceName,
          year: selectedYear,
          month: selectedMonth,
          client_id: clientId,
        }
      });
      
      if (response.status === 200) {
        alert('Compliance marked as pending successfully');
        // Refresh complianceClients state to reflect the change
        fetchClientsData()
      }
    } catch (error) {
      console.error('Error marking compliance as pending:', error);
      alert('Failed to mark compliance as pending');
    }
  };
  const fetchClientsData = async () => {
    try {
      const clientsData = await fetchComplianceClients(
        'monthly',
        complianceName,
        selectedYear,
        null, // No quarter for monthly compliance
        selectedMonth
      );
      console.log('skdn')
      console.log(clientsData)
      setComplianceClients(clientsData);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };
  useEffect(() => {
 

    if (complianceName !== 'Compliance Not Found') {
      fetchClientsData();
    }
  }, [complianceName, selectedYear, selectedMonth]);


  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Monthly Compliance</h1>
      <h2>{complianceName}</h2>
      <div style={styles.searchContainer}>
        <div style={styles.searchWrapper}>
          <FaSearch style={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search by name or company type..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={styles.searchInput}
          />
        </div>

        <div style={styles.dropdownContainer}>
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            style={styles.dropdown}
          >
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            style={styles.dropdown}
          >
            {monthOptions.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </div>
        {isAddModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <div style={styles.modalHeader}>Add Task</div>
            <button
              style={styles.closeModalButton}
              onClick={() => setAddModalOpen(false)}
            >
              <FaTimes />
            </button>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="title">
                Task Title:
              </label>
              <input
                type="text"
                name="title"
                value={taskData.title}
                onChange={handleInputChange}
                style={styles.input}
                readOnly
                />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="description">
                Description:
              </label>
              <input
                type="text"
                name="desc"
                value={taskData.desc}
                onChange={handleInputChange}
                style={styles.input}
                readOnly

              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="deadline">
                Deadline:
              </label>
              <input
                type="date"
                name="deadline"
                value={taskData.deadline}
                onChange={handleInputChange}
                style={styles.input}
                min={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="assignedTo">
                Assigned To:
              </label>
              <select
                name="assignedTo"
                value={taskData.assignedTo}
                onChange={handleInputChange}
                style={styles.input}
              >
                <option value="">Select a team member</option>
                {team.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.name}
                  </option>
                ))}
                {user.role === 'Member' && (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                )}
              </select>
            </div>
         
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="client">
                Client:
              </label>
              <select
                name="client"
                value={taskData.client}
                onChange={handleInputChange}
                style={styles.input}
                disabled

              >
                <option value="">Select a client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
            <div style={styles.formActions}>
              <button onClick={handleAddTask} style={styles.saveButton}>
                Save
              </button>
              <button
                onClick={() => setAddModalOpen(false)}
                style={styles.closeButton}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      </div>

      {/* Client Table */}
      <table style={styles.table}>
        <thead>
          <tr>
          {['Name', 'Company Type','Deadline','Status', 'Task Assigned To',"Task Status",  'Filed By', 'Actions'].map(header => (
              <th key={header} style={styles.th}>{header}</th>
            ))}
          </tr>
        </thead>
        
        <tbody>
          {clients.map((client, index) => {
            
const isClientInCompliance = complianceClients.some(
    complianceClient => complianceClient.client?.id === client.id && complianceClient.user != null
  );
              const deadlineDate = new Date(getComplianceDeadline(complianceName));
            const isOverdue = !isClientInCompliance && new Date() > deadlineDate;

// Find the complianceClient in the list that matches the conditions.
const comClient = complianceClients.find(
    complianceClient => complianceClient.client?.id === client.id 
  );
  console.log(comClient)

            return (
              <tr key={index}>
                <td style={styles.td}>
                    {client.name}</td>
                <td style={styles.td}>
                  {client.company_type}</td>
  

                {/* <td style={styles.td}>{getComplianceDeadline(complianceName)}</td> */}
                <td style={{ ...styles.td, color: isOverdue ? 'red' : 'black' }}>
{(() => {
                const date = deadlineDate;
                const day = date.getDate();
                const month = date.toLocaleString("default", { month: "short" });
                const year = date.getFullYear();
                return `${day} ${month}, ${year}`;
              })()}
</td>
                <td style={{ ...styles.td, color: isOverdue ? 'red' : 'black' }}>
                  {isClientInCompliance ? 'Complete' : isOverdue ? 'Overdue' : 'Pending'}
                </td> 
                <td style={styles.td}>
  {!isClientInCompliance && comClient ? comClient.task.assigned_to.name: 'NA'}
</td>   
<td style={styles.td}>
{!isClientInCompliance && comClient ? comClient.task.status : 'NA'}
</td>   
                            <td style={styles.td}>{isClientInCompliance && complianceClients.find(complianceClient => complianceClient.client?.id === client.id)?.user?.name || 'N/A'}</td>
                <td style={styles.td}>
                {isClientInCompliance ? (
                    <button
                      style={styles.actionButton}
                      onClick={() => handlePendingCompliance(client.id)}
                    >
                      Mark Pending
                    </button>
                  ) : (
                   <div>
                     <button
                      style={styles.actionButton}
                      onClick={() => handleCompleteCompliance(client.id)}
                    >
                      Mark Complete
                    </button>

<button
style={styles.actionButton}
onClick={() => handleAddModal(true,client)} >
Assign as Task
</button>
                   </div>
                  )}
                 
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  // Modal Styles
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
  },
  modal: {
    position: "relative",
    background: "#FFFFFF",
    padding: "30px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
    zIndex: "1000",
    width: "50%",
    maxWidth: "600px",
    height: "auto",
    borderRadius: "12px",
  },

  closeModalButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "20px",
  },

  modalHeader: {
    color: "#2c2e34",
    marginBottom: "20px",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "bold",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "6px",
    fontSize: "16px",
  },
  formActions: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  saveButton: {
    backgroundColor: "#4CAF50",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
  },
  closeButton: {
    backgroundColor: "#F44336",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "10px",
    transition: "background-color 0.3s ease",
  },
  // General Container Styles
  container: {
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    color: "#2c2e34",
    marginBottom: "12px",
  },

  // Search Container Styles
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  searchWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    position: "absolute",
    left: "10px",
    color: "#aaa",
  },
  searchInput: {
    width: "200px",
    padding: "8px 8px 8px 30px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginRight: "10px",
  },

  // Dropdown Container Styles
  dropdownContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  dropdown: {
    padding: "8px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "16px",
  },

  // Button Styles
  addButton: {
    backgroundColor: "#2c2e34",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },

  // Table Styles
  table: {
    margin: "0 auto",
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f3f2f2",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },
};

export default MonthlyCompliancePage;