import React from 'react';

function MyAccount() {
  return (
    <div>
      <h1>TMy Account</h1>
    </div>
  );
}

export default MyAccount