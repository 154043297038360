import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaSearch, FaTimes, FaEllipsisV, FaEdit } from "react-icons/fa";
import { hexToRgb } from '@mui/material';
import { height } from '@mui/system';

const ClientDetail = () => {
  const { client_id } = useParams();
  const [client, setClient] = useState(null);
  const [team, setTeam] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const loadTeamMembers = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }
      const response = await axios.get(`https://backend.promptitude.deloai.com/user/users?user_id=${userId}`);
      setTeam(response.data);
    } catch (error) {
      console.error("Failed to load team members", error);
    }
  };

  const handleUpdateClient = async (updatedClient) => {
    try {
      console.log(updatedClient);
 // Create a copy of the updated client and remove the tasks property
 const clientToUpdate = { ...updatedClient };
 delete clientToUpdate.tasks;
 if(!updatedClient.manager){
    alert("Please assign  a manager")
    return 
  }

 console.log(clientToUpdate);
 await axios.put(`https://backend.promptitude.deloai.com/clients/${clientToUpdate.id}`, clientToUpdate);      fetchClient();
      setUpdateModalOpen(false);
    } catch (error) {
      console.error("Error updating client:", error);
      alert(`Failed to update client: ${error.response?.data?.message || error.message}`);
    }
  };

  useEffect(() => {
    if (user?.role === "Admin") {
      loadTeamMembers();
    }
  }, []);

  const fetchClient = async () => {
    try {
      const response = await axios.get(`https://backend.promptitude.deloai.com/clients/${client_id}`);
      let temp_client = response.data
      if (temp_client.yearly_compliance && temp_client.yearly_compliance.length > 0) {
        temp_client.yearly_compliance_subfields = temp_client.yearly_compliance; // Store original list in subfields

        temp_client.yearly_compliance = true;
      } else {
        temp_client.yearly_compliance = false;
      }

      // Update monthly_compliance
      if (temp_client.monthly_compliance && temp_client.monthly_compliance.length > 0) {
        temp_client.monthly_compliance_subfields = temp_client.monthly_compliance; // Store original list in subfields

        temp_client.monthly_compliance = true;
      } else {
        temp_client.monthly_compliance = false;
      }

      // Update quarterly_compliance
      if (temp_client.quarterly_compliance && temp_client.quarterly_compliance.length > 0) {
        temp_client.quarterly_compliance_subfields = temp_client.quarterly_compliance; // Store original list in subfields

        temp_client.quarterly_compliance = true;
      } else {
        temp_client.quarterly_compliance = false;
      }
      setClient(temp_client);
      setLoading(false);
    } catch (err) {
      setError('Client not found or an error occurred while fetching data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClient();
  }, [client_id]);

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return <div style={styles.loadingSpinner}>Loading...</div>;
  }

  if (error) {
    return <div style={styles.errorMessage}>{error}</div>;
  }

  return (
    <div style={styles.container}>
      {isUpdateModalOpen && selectedClient && (
        <ClientModal
          title="Update Client"
          client={selectedClient}
          setClient={setSelectedClient}
          onClose={() => setUpdateModalOpen(false)}
          onSave={() => handleUpdateClient(selectedClient)}
          team={team}
          user={user}
        />
      )}
      {client && (
        <div style={styles.gridContainer}>
          {/* Top 50% - Client Details */}
          <div style={styles.paper}>
            <div style={styles.clientHeader}>
              <h2>Client Details</h2>
              <button onClick={() => {

          setSelectedClient({...client,manager: client.manager? client.manager.id : ''});
          setUpdateModalOpen(true);
        }} style={styles.editButton}>Edit Client</button>
            </div>
            <div style={styles.clientDetails}>
              <div><strong>Name:</strong> {client.name}</div>
              <div><strong>Email:</strong> {client.email}</div>
              <div><strong>Company Type:</strong> {client.company_type}</div>
              <div><strong>Manager:</strong> {client.manager ? client.manager.name : 'NA'}</div>
              <div><strong>PAN:</strong> {client.PAN}</div>
              <div><strong>CIN:</strong> {client.CIN}</div>
              <div><strong>GSTIN:</strong> {client.GSTIN}</div>
              <div><strong>Phone Number:</strong> {client.phno}</div>
              <div><strong>City:</strong> {client.city}</div>
              <div><strong>State:</strong> {client.state}</div>
              <div><strong>Turnover:</strong> {client.turnover}</div>
            </div>
          </div>

          {/* Bottom 50% - Tabs */}
          <div style={styles.paper}>
            <div style={styles.tabs}>
              <button style={{ ...styles.tabButton, ...(activeTab === 0 ? styles.activeTabButton : {}) }} onClick={() => handleTabChange(0)}>Tasks</button>
              <button style={{ ...styles.tabButton, ...(activeTab === 1 ? styles.activeTabButton : {}) }} onClick={() => handleTabChange(1)}>Documents</button>
              <button style={{ ...styles.tabButton, ...(activeTab === 2 ? styles.activeTabButton : {}) }} onClick={() => handleTabChange(2)}>Yearly Compliance</button>
              <button style={{ ...styles.tabButton, ...(activeTab === 3 ? styles.activeTabButton : {}) }} onClick={() => handleTabChange(3)}>Monthly Compliance</button>
              <button style={{ ...styles.tabButton, ...(activeTab === 4 ? styles.activeTabButton : {}) }} onClick={() => handleTabChange(4)}>Quarterly Compliance</button>
            </div>
            <div style={styles.tabContent}>
              {activeTab === 0 && (
                <TaskTable
                  tasks={client.tasks}
                  loadTasks={fetchClient}
                />
              )}
              {activeTab === 1 && (
                <div style={{ textAlign: 'left', marginTop: '40px' }}>
                <h3 style={{ marginBottom: '10px' }}>Documents</h3>
                <h5 style={{ color: '#888' }}>Coming Soon</h5>
                {/* <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                  {client.docs.map((doc, index) => (
                    <li key={index} style={{ marginBottom: '5px' }}>
                      <a href={doc} target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}>Document {index + 1}</a>
                    </li>
                  ))}
                </ul> */}
                {/* <input type="text" placeholder="Add Document URL" style={{ padding: '8px', width: '80%', marginTop: '10px' }} /> */}
                {/* <button style={{ padding: '10px 15px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', marginTop: '10px' }}>Add Document</button> */}
              </div>
              )}
              {activeTab === 2 && (
 <ComplianceCard
 title="Yearly Compliance"
 complianceList={client.yearly_compliance_subfields}
 description="Yearly compliance items that the client must adhere to."
/>                
              )}
              {activeTab === 3 && (
                 <ComplianceCard
                 title="Monthly Compliance"
                 complianceList={client.monthly_compliance_subfields}
                 description="Monthly compliance items that the client must adhere to."
               />
              )}
              {activeTab === 4 && (
                 <ComplianceCard
                 title="Quarterly Compliance"
                 complianceList={client.quarterly_compliance_subfields}
                 description="Quarterly compliance items that the client must adhere to."
               />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const ComplianceCard = ({ title, complianceList, description }) => {
    return (
      <div style={styles.complianceCard}>
        <h3 style={styles.complianceCardTitle}>{title}</h3>
        <p style={styles.complianceCardDescription}>{description}</p>
        <ul style={styles.complianceCardList}>
          {complianceList && complianceList.length > 0 ? (
            complianceList.map((item, index) => (
              <li key={index} style={styles.complianceCardListItem}>{item}</li>
            ))
          ) : (
            <li style={styles.complianceCardEmptyMessage}>No compliance items available.</li>
          )}
        </ul>
      </div>
    );
  };
const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    // height:'100vh',
    // overflowY: 'auto',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gap: '20px',
  },
  paper: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  clientHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  editButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  clientDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '10px',
  },
  tabs: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
  },
  tabButton: {
    backgroundColor: '#f1f1f1',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  activeTabButton: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  tabContent: {
    marginTop: '20px',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  addDocumentButton: {
    padding: '10px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  loadingSpinner: {
    textAlign: 'center',
    margin: '50px 0',
    fontSize: '24px',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    margin: '50px 0',
  },
  // Modal Styles
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    padding: "30px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
    zIndex: "1000",
    width: "50%",
    maxWidth: "800px",
    height: "70%",
    borderRadius: "12px",
    overflowY: "auto",
  },
  modalHeader: {
    color: "#2c2e34",
    marginBottom: "20px",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
  },
  
  // Button Styles
  addButton: {
    backgroundColor: "#2c2e34",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },
  saveButton: {
    backgroundColor: "#4CAF50",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
  },
  closeButton: {
    backgroundColor: "#F44336",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "10px",
    transition: "background-color 0.3s ease",
  },

  // Label & Input Styles
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "bold",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "6px",
    fontSize: "16px",
  },

  // Table Styles
  table: {
    margin: "0 auto",
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f3f2f2",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },

  // Dropdown Styles
  dropdownButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "white",
    border: "1px solid #ddd",
    listStyle: "none",
    padding: "0",
    margin: "0",
    minWidth: "120px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: "1000",
    borderRadius: "5px",
  },
  dropdownItem: {
    padding: "8px",
    cursor: "pointer",
  },

  // Compliance Section Styles
  complianceContainer: {
    marginBottom: "20px",
  },
  complianceSection: {
    marginBottom: "15px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  complianceLabel: {
    fontWeight: "600",
    color: "#444",
    display: "block",
    marginBottom: "10px",
  },
  subfieldsContainer: {
    paddingLeft: "15px",
    marginTop: "10px",
  },
  subfieldItem: {
    marginBottom: "8px",
  },
  subfieldLabel: {
    color: "#555",
  },

  // Form Action Styles
  formActions: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  formGroup: {
    marginBottom: "10px",
  },
  complianceCard: {
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  },
  complianceCardTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
  },
  complianceCardDescription: {
    fontSize: '14px',
    marginBottom: '15px',
    color: '#666',
  },
  complianceCardList: {
    listStyleType: 'disc',
    paddingLeft: '20px',
  },
  complianceCardListItem: {
    fontSize: '16px',
    marginBottom: '8px',
  },
  complianceCardEmptyMessage: {
    fontSize: '16px',
    color: '#999',
  },


};


const ClientModal = ({ title, client, setClient, onClose, onSave, team, user }) => {
    const handleComplianceChange = (complianceType, value) => {
      setClient({ ...client, [complianceType]: value });
    };
  
    const handleSubfieldChange = (complianceType, subfield, checked) => {
      const updatedSubfields = checked
        ? [...(client[complianceType + "_subfields"] || []), subfield]
        : client[complianceType + "_subfields"].filter((item) => item !== subfield);
      setClient({ ...client, [complianceType + "_subfields"]: updatedSubfields });
    };
  
    const complianceCheckboxes = (complianceType, subfields) => (
      <div style={styles.complianceSection}>
        <label style={styles.complianceLabel}>
          <input
            type="checkbox"
            checked={client[complianceType] || false}
            onChange={(e) => handleComplianceChange(complianceType, e.target.checked)}
          />
          {complianceType.replace('_', ' ')}
        </label>
        {client[complianceType] && (
          <div style={styles.subfieldsContainer}>
            {subfields.map((subfield, index) => (
              <div key={index} style={styles.subfieldItem}>
                <label style={styles.subfieldLabel}>
                  <input
                    type="checkbox"
                    checked={client[complianceType + "_subfields"]?.includes(subfield) || false}
                    onChange={(e) => handleSubfieldChange(complianceType, subfield, e.target.checked)}
                  />
                  {subfield}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  
    return (
      <div style={styles.modal}>
        <h2 style={styles.modalHeader}>{title}</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSave();
          }}
        >
          {/* Existing Form Fields */}
          <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
          <div style={{ flex: 1 }}>
             <label>Name:</label>
             <input
              type="text"
              value={client.name}
              onChange={(e) => setClient({ ...client, name: e.target.value })}
              style={styles.input}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label>Email:</label>
            <input
              type="email"
              value={client.email}
              onChange={(e) => setClient({ ...client, email: e.target.value })}
              style={styles.input}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
          <div style={{ flex: 1 }}>
            <label>Company Type:</label>
            <select
              value={client.company_type}
              onChange={(e) => setClient({ ...client, company_type: e.target.value })}
              style={styles.input}
            >
              <option value="">Select Company Type</option>
              <option value="Private Limited">Private Limited</option>
              <option value="Public Limited">Public Limited</option>
              <option value="Partnership Firm">Partnership Firm</option>
              <option value="Proprietorship">Proprietorship</option>
              <option value="Limited Liability Partnership">Limited Liability Partnership</option>
              <option value="One Person Company">One Person Company</option>
              <option value="Individual">Individual</option>
              <option value="Co-operative Society">Co-operative Society</option>
              <option value="Trust">Trust</option>
            </select>
          </div>
          {user.role === 'Admin' && (
            <div style={{ flex: 1 }}>
              <label>Manager:</label>
              <select
                value={client.manager}
                onChange={(e) => setClient({ ...client, manager: e.target.value })}
                style={styles.input}
              >
                <option value="">Select Manager</option>
                {team.map((member) => (
                  <option key={member.id} value={member.id}>{member.name}</option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
          <div style={{ flex: 1 }}>
            <label>PAN:</label>
            <input
              type="text"
              value={client.PAN}
              onChange={(e) => setClient({ ...client, PAN: e.target.value })}
              style={styles.input}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label>CIN:</label>
            <input
              type="text"
              value={client.CIN}
              onChange={(e) => setClient({ ...client, CIN: e.target.value })}
              style={styles.input}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label>GSTIN:</label>
            <input
              type="text"
              value={client.GSTIN}
              onChange={(e) => setClient({ ...client, GSTIN: e.target.value })}
              style={styles.input}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
          <div style={{ flex: 1 }}>
            <label>Phone Number:</label>
            <input
              type="text"
              value={client.phno}
              onChange={(e) => setClient({ ...client, phno: e.target.value })}
              style={styles.input}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label>City:</label>
            <input
              type="text"
              value={client.city}
              onChange={(e) => setClient({ ...client, city: e.target.value })}
              style={styles.input}
            />
          </div>
          <div style={{ flex: 1 }}>
            <label>State:</label>
            <input
              type="text"
              value={client.state}
              onChange={(e) => setClient({ ...client, state: e.target.value })}
              style={styles.input}
            />
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Turnover:</label>
          <input
            type="number"
            value={client.turnover}
            onChange={(e) => setClient({ ...client, turnover: e.target.value })}
            style={styles.input}
          />
         </div>
  
          {/* Compliance Checkboxes */}
          <div style={styles.complianceContainer}>
            {complianceCheckboxes("yearly_compliance", [
              "PT Payment yearly",
              "Form DPT 3 for FY 2023-24 for Companies",
              "IEC Code Renewal",
              "Income Tax Returns for Individuals",
              "AOC 4 Filing",
              "MGT7 Filing",
              "Audit Report in Form 3CB-3CD and 3CA-3CD",
              "DIR 3 KYC for Directors of Company's and LLP's",
              "AOC 4 Filing for Companies",
              "Form 8 Filing for LLP's",
              "Form 4 LLP",
              "Income Tax Returns for Non Corporate's who needs Audit and Corporates",
              "LUT Generation",
            ])}
            {complianceCheckboxes("monthly_compliance", [
              "TDS Payment",
              "GST R1",
              "GST 3B",
  
              "Professional Tax (PT) on Salaries",
              "Provident Fund (PF) & ESI Returns Payment",
              "Monthly Income Statement",
            ])}
            {complianceCheckboxes("quarterly_compliance", [
              "TDS Returns",
              "Advance Tax",
              "GST R1",
              "GST 3B",
            ])}
          </div>
  
          <div style={styles.formActions}>
            <button type="submit" style={styles.saveButton}>Save</button>
            <button type="button" onClick={onClose} style={styles.closeButton}>Close</button>
          </div>
        </form>
      </div>
    );
  };

  const TaskTable = ({ tasks,loadTasks}) => {
    const handleStatusChange = async (taskId, newStatus,dropdown) => {
      try {
        // Send PATCH request to update the task status
        const response = await axios.patch(
          `https://backend.promptitude.deloai.com/tasks/${taskId}/status`,
          { status: newStatus }
        );
        console.log("Task status updated successfully", response.data);
        // You may need to reload the tasks to see the updated status or modify the state directly
        dropdown.style.display = "none";
  
        loadTasks();
      } catch (error) {
        console.error("Failed to update task status", error);
      }
    };
  
    const getStatusStyle = (status) => {
      switch (status) {
        case "Pending":
          return { backgroundColor: "#f0ad4e", color: "#fff" }; // Yellow
        case "In Progress":
          return { backgroundColor: "#5bc0de", color: "#fff" }; // Blue
        case "Completed":
          return { backgroundColor: "#5cb85c", color: "#fff" }; // Green
        case "On Hold":
          return { backgroundColor: "#d9534f", color: "#fff" }; // Red
        default:
          return { backgroundColor: "#777", color: "#fff" }; // Default Gray
      }
    };
  
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            {["Title", "Description", "Assigned To","Assigned By","Reporting To","Status" ,"Deadline" ].map(
              (header) => (
                <th key={header} style={styles.th}>
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr key={index}>
              <td style={styles.td}>{task.title}</td>
              <td style={styles.td}>{task.desc}</td>
              <td style={styles.td}>{task.assigned_to ? task.assigned_to.name : "NA"}</td>
              <td style={styles.td}>{task.assigned_by ? task.assigned_by.name : "NA"}</td>
              <td style={styles.td}>{task.reporting_to ? task.reporting_to.name : "NA"}</td>
              <td style={{ ...styles.td, position: "relative" }}>
                <div
                  style={{
                    ...getStatusStyle(task.status),
                    padding: "5px 10px",
                    borderRadius: "12px",
                    textAlign: "center",
                    cursor: task.status === "Completed" ? "default" : "pointer",
                    display: "inline-block",
                    fontSize: "14px",
                  }}
                  onClick={(e) => {
                    if (task.status !== "Completed") {
                      // Toggle dropdown visibility
                      const dropdown = e.currentTarget.nextSibling;
                      dropdown.style.display =
                        dropdown.style.display === "block" ? "none" : "block";
                    }
                  }}
                >
                  {task.status}
                </div>
                {task.status !== "Completed" && (
                  <div style={{ ...styles.statusDropdownMenu, display: "none" }}>
                    {["Pending", "In Progress", "Completed", "On Hold"].map((status) => (
                      <div
                        key={status}
                        style={styles.dropdownItem}
                        onClick={(e) => {
                          if (window.confirm(`Are you sure you want to change the status to '${status}'?`)) {
                            handleStatusChange(task.id, status,e.currentTarget.parentNode);
                          }
                        }}
                      >
                        {status}
                      </div>
                    ))}
                  </div>
                )}
              </td>
              <td
                style={{
                  ...styles.td,
                  color:
                    new Date(task.deadline) < new Date() &&
                    task.status !== "Completed"
                      ? "red"
                      : "inherit",
                }}
              >
                {(() => {
                  const date = new Date(task.deadline);
                  const day = date.getDate();
                  const month = date.toLocaleString("default", { month: "short" });
                  const year = date.getFullYear();
                  return `${day} ${month}, ${year}`;
                })()}
              </td>
             
              
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
export default ClientDetail;