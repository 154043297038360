import React, { useState, useEffect } from "react";
import "./Log.css";
import { FaSearch } from "react-icons/fa";
import axios from "axios";

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [sortBy, setSortBy] = useState("time");
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"));

  // Helper function to convert UTC to IST
  const convertUTCToIST = (utcDateString) => {
    if (!utcDateString) return "N/A";
    // console.log(utcDateString)

    try {
      const date = new Date(utcDateString);
      // Add 5 hours and 30 minutes in milliseconds
      const istTimestamp = date.getTime() + 5.5 * 60 * 60 * 1000;
      const istDate = new Date(istTimestamp);
      return istDate.toLocaleString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
    } catch (error) {
      console.error("Error converting time:", error);
      return "Invalid Date";
    }
  };

  // Fetch logs from backend
  const loadLogs = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }

      let url = `https://backend.promptitude.deloai.com/user/logs?user_id=${userId}&sort_by=${sortBy}`;
      if (filterDate) url += `&date=${filterDate}`;
      const response = await axios.get(url);
      setLogs(response.data.logs || []);
    } catch (error) {
      console.error("Failed to load logs:", error);
    }
  };

  useEffect(() => {
    loadLogs();
  }, [filterDate, sortBy]);

  // Filter logs based on search term
  const filteredLogs = logs.filter(
    (log) =>
      log.user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      log.user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Open modal to view more details
  const handleViewLog = (log) => {
    setSelectedLog(log);
    setModalOpen(true);
  };

  return (
    <div className="logs-container">
      <h1 className="page-heading">Activity Logs</h1>

      {/* Search and Filter */}
      <div className="filter-container">
        <div className="input-wrapper">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search by user name or email..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="date-sort-container">
          <label>
            Date:
            <input
              type="date"
              value={filterDate}
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </label>
          <label>
            Sort By:
            <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
              <option value="time">Time</option>
              <option value="user">User</option>
            </select>
          </label>
        </div>
      </div>

      {/* Logs Table */}
      <table className="logs-table">
        <thead>
          <tr>
            <th>User Name</th>
            <th>Email</th>
            <th>Login Time</th>
            <th>Logout Time</th>
            <th>Login Address</th>
            <th>Logout Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredLogs.map((log, index) => (
            <tr key={index}>
              <td>{log.user.name}</td>
              <td>{log.user.email}</td>
              <td>{convertUTCToIST(log.login_time)}</td>
              <td>
                {log.checkout_time
                  ? convertUTCToIST(log.checkout_time)
                  : "N/A"}
              </td>
              <td>{log.login_address || "N/A"}</td>
              <td>{log.logout_address || "N/A"}</td>
              <td>
                <button
                  className="view-btn"
                  onClick={() => handleViewLog(log)}
                >
                  View More
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Viewing Details */}
      {isModalOpen && selectedLog && (
        <div className="modal">
          <h2>Log Details</h2>
          <p>
            <strong>User Name:</strong> {selectedLog.user.name}
          </p>
          <p>
            <strong>Email:</strong> {selectedLog.user.email}
          </p>
          <p>
            <strong>Login Time:</strong>{" "}
            {convertUTCToIST(selectedLog.login_time)}
          </p>
          <p>
            <strong>Logout Time:</strong>{" "}
            {selectedLog.checkout_time
              ? convertUTCToIST(selectedLog.checkout_time)
              : "N/A"}
          </p>
          <p>
            <strong>Login Address:</strong> {selectedLog.login_address || "N/A"}
          </p>
          <p>
            <strong>Logout Address:</strong>{" "}
            {selectedLog.logout_address || "N/A"}
          </p>
          <p>
            <strong>Login Coordinates:</strong>{" "}
            {selectedLog.login_coordinates
              ? `Lat: ${selectedLog.login_coordinates.lat}, Lng: ${selectedLog.login_coordinates.lng}`
              : "N/A"}
          </p>
          <p>
            <strong>Logout Coordinates:</strong>{" "}
            {selectedLog.logout_coordinates
              ? `Lat: ${selectedLog.logout_coordinates.lat}, Lng: ${selectedLog.logout_coordinates.lng}`
              : "N/A"}
          </p>
          <button className="close-btn" onClick={() => setModalOpen(false)}>
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default Logs;
