import React, { useState } from "react";
import { FaUser, FaBell } from "react-icons/fa"; // Import icons
import { Link, useNavigate } from "react-router-dom"; // Import Link for routing
import "./Header.css"; // Assuming you still have some global styles

function Header() {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    const sessionId = sessionStorage.getItem("session_id");
    if (!sessionId) {
      alert("No active session found.");
      navigate("/login");
      return;
    }

    try {
      // Fetch user coordinates using Geolocation API
      const getCoordinates = () =>
        new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            (error) => {
              reject(error);
            }
          );
        });

      const coordinates = await getCoordinates();

      const response = await fetch(
        `https://backend.promptitude.deloai.com/user/logout/${sessionId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ coordinates }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        sessionStorage.clear(); // Clear session storage
        alert("You have been logged out.");
        navigate("/login"); // Redirect to login page
      } else {
        alert(`Logout failed: ${data.error}`);
      }
    } catch (error) {
      console.error("Logout error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div
      className="header"
      style={{
        backgroundColor: "#2c2e34",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        fontWeight: "bold",
        position: "relative",
        borderBottom: "2px solid #fff",
      }}
    >
      <h1 style={{ margin: 0, color: "#fff" }}>Promptitude</h1>
      <div className="header-icons" style={{ display: "flex", gap: "15px" }}>
        <Link to="/notifications">
          <FaBell
            className="header-icon"
            style={{
              fontSize: "25px",
              color: "#2c2e34",
              cursor: "pointer",
              backgroundColor: "#fff",
              padding: "8px",
              borderRadius: "25px",
              transition: "color 0.3s",
            }}
          />
        </Link>

        <div
          className="profile-container"
          style={{ position: "relative" }}
          onMouseEnter={() => setIsProfileMenuOpen(true)}
          onMouseLeave={() => setIsProfileMenuOpen(false)}
        >
          <FaUser
            className="header-icon"
            style={{
              fontSize: "25px",
              color: "#2c2e34",
              cursor: "pointer",
              backgroundColor: "#fff",
              padding: "8px",
              borderRadius: "25px",
              transition: "color 0.3s",
            }}
          />
          {/* Dropdown Menu */}
          {isProfileMenuOpen && (
            <div
              className="dropdown-menu2"
              style={{
                position: "absolute",
                top: "40px",
                right: "0",
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                overflow: "hidden",
                zIndex: 1000,
              }}
            >
              <Link
                to="/profile"
                style={{
                  display: "block",
                  padding: "10px 20px",
                  textDecoration: "none",
                  color: "#2c2e34",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#f0f0f0")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#fff")
                }
              >
                View Profile
              </Link>
              <div
                onClick={handleLogout}
                style={{
                  display: "block",
                  padding: "10px 20px",
                  textDecoration: "none",
                  color: "#2c2e34",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#f0f0f0")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#fff")
                }
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
