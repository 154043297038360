import React, { useState, useEffect } from "react";
import { FaSearch, FaTimes, FaEllipsisV ,FaEdit} from "react-icons/fa";
import axios from "axios";

const MyTasks = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const [taskData, setTaskData] = useState({
    title: "",
    desc: "",
    deadline: "",
    assignedTo: "",
    reportedTo: "",
    client: "",
  });
  
  const [tasks, setTasks] = useState([]);
  const user = JSON.parse(sessionStorage.getItem('user'));
  const [editingTaskId, setEditingTaskId] = useState(null);

  const [team, setTeam] = useState([]);
  const [allTeam, setallTeam] = useState([]);

  const [clients, setClients] = useState([]);

  useEffect(() => {
    loadTeamMembers();
    loadClients();
    loadAllMembers();
    loadTasks();
  }, []);

  const loadTeamMembers = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }
      const response = await axios.get(`https://backend.promptitude.deloai.com/user/users?user_id=${userId}`);
      setTeam(response.data);
    } catch (error) {
      console.error("Failed to load team members", error);
    }
  };

  const loadTasks = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }
      
      // Make a request to the server, passing the user ID as a query parameter
      const response = await axios.get(`https://backend.promptitude.deloai.com/tasks/assigned`, {
        params: {
          user_id: userId
        }
      });
      
      // Set the tasks from the response
      console.log(response.data)
      setTasks(response.data);
    } catch (error) {
      console.error("Failed to load tasks", error);
    }
  };

  const loadAllMembers = async () => {
    try {
      
      const response = await axios.get(`https://backend.promptitude.deloai.com/user/users`);
      setallTeam(response.data);
    } catch (error) {
      console.error("Failed to load team members", error);
    }
  };
  const loadClients = async () => {
    try {
      const response = await axios.get("https://backend.promptitude.deloai.com/clients");      
      // Assuming response.data is an array of client objects
      
      // Set the clients state
      setClients(response.data);
    } catch (error) {
      console.error("Failed to load clients", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    setTaskData({ ...taskData, [name]: value });
  };
  const handleEditTask = (task) => {
    if(task.status === 'Completed'){
      alert("Task already Completed. Cant Edit Task")
      return
    }
    setTaskData({
      title: task.title,
      desc: task.desc,
      deadline: task.deadline,
      assignedTo: task.assigned_to?.id || "",
      reportedTo: task.reporting_to?.id || "",
      client: task.client?.id || "",
    });
    setEditingTaskId(task.id);
    setEditModalOpen(true);
  };
  const handleUpdateTask = async () => {
    try {
      if (!editingTaskId) return;
      if (!taskData.title) {
        alert("Title is required");
        return;
      }
      if (!taskData.deadline) {
        alert("Please add Deadline for the Task");
        return;
      }
      if (!taskData.assignedTo) {
        alert("Task should be assigned to an user");
        return;
      }
      if (!taskData.reportedTo) {
        alert("Please select reporting to");
        return;
      }
      

      const response = await axios.put(`https://backend.promptitude.deloai.com/tasks/${editingTaskId}`, taskData);
      console.log("Task updated successfully", response.data);
      setEditModalOpen(false);
      setTaskData({ title: "", desc: "", deadline: "", assignedTo: "", reportedTo: "", client: "" });
      loadTasks();
    } catch (error) {
      console.error("Error updating task", error);
    }
  };
  const handleAddTask = async () => {
    try {
      console.log(taskData);
      if(user.role === 'Member'){
        taskData.reportedTo = user.id;
      }
      taskData.assigned_by=user.id;
      if(!taskData.title ){
        alert("Title is required");
        return;
      }
      if(!taskData.deadline){
        alert("Please add Deadline for the Task");
        return;
      }
      if(!taskData.assignedTo){
        alert("Task should be assigned to an user");
        return;
      }
      if(!taskData.reportedTo){
        alert("Please select reporting to");
        return;
      }

      const response = await axios.post("https://backend.promptitude.deloai.com/tasks", taskData);
      console.log("Task added successfully", response.data);
      setAddModalOpen(false);
      // Reset task data after successful addition
      setTaskData({ title: "", desc: "", deadline: "", assignedTo: "", reportedTo: "", client: "" });
      loadTasks(); // Reload tasks to include the newly added one
    } catch (error) {
      console.error("Error adding task", error);
    }
  };

  const filteredTasks = tasks.filter((task) =>
    [task.title, task.desc].some((field) =>
      field?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Tasks - Assigned To Me</h1>
      <div style={styles.searchContainer}>
        <div style={styles.searchWrapper}>
          <FaSearch style={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search by task title or description..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={styles.searchInput}
          />
        </div>
        <button onClick={() => setAddModalOpen(true)} style={styles.addButton}>
          Add Task
        </button>
      </div>

      {isAddModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <div style={styles.modalHeader}>Add Task</div>
            <button
              style={styles.closeModalButton}
              onClick={() => setAddModalOpen(false)}
            >
              <FaTimes />
            </button>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="title">
                Task Title:
              </label>
              <input
                type="text"
                name="title"
                value={taskData.title}
                onChange={handleInputChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="description">
                Description:
              </label>
              <input
                type="text"
                name="desc"
                value={taskData.desc}
                onChange={handleInputChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="deadline">
                Deadline:
              </label>
              <input
                type="date"
                name="deadline"
                value={taskData.deadline}
                onChange={handleInputChange}
                style={styles.input}
                min={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="assignedTo">
                Assigned To:
              </label>
              <select
                name="assignedTo"
                value={taskData.assignedTo}
                onChange={handleInputChange}
                style={styles.input}
              >
                <option value="">Select a team member</option>
                {team.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.name}
                  </option>
                ))}
                {user.role === 'Member' && (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                )}
              </select>
            </div>
            <div style={styles.formGroup}>
              {user.role === 'Admin' && (
                <div>
                  <label style={styles.label} htmlFor="reportedTo">
                    Reported To:
                  </label>
                  <select
                    name="reportedTo"
                    value={taskData.reportedTo}
                    onChange={handleInputChange}
                    style={styles.input}
                  >
                    <option value="">Select a team member</option>
                    {allTeam.map((member) => (
                      <option key={member.id} value={member.id}>
                        {member.name}
                      </option>
                    ))}
                    {user.role === 'Member' && (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    )}
                  </select>
                </div>
              )}
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="client">
                Client:
              </label>
              <select
                name="client"
                value={taskData.client}
                onChange={handleInputChange}
                style={styles.input}
              >
                <option value="">Select a client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
            <div style={styles.formActions}>
              <button onClick={handleAddTask} style={styles.saveButton}>
                Save
              </button>
              <button
                onClick={() => setAddModalOpen(false)}
                style={styles.closeButton}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}


{isEditModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <div style={styles.modalHeader}>Edit Task</div>
            <button
              style={styles.closeModalButton}
              onClick={() => setEditModalOpen(false)}
            >
              <FaTimes />
            </button>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="title">
                Task Title:
              </label>
              <input
                type="text"
                name="title"
                value={taskData.title}
                onChange={handleInputChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="desc">
                Description:
              </label>
              <input
                type="text"
                name="desc"
                value={taskData.desc}
                onChange={handleInputChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="deadline">
                Deadline:
              </label>
              <input
                type="date"
                name="deadline"
                value={taskData.deadline}
                onChange={handleInputChange}
                style={styles.input}
                min={new Date().toISOString().split('T')[0]}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="assignedTo">
                Assigned To:
              </label>
              <select
                name="assignedTo"
                value={taskData.assignedTo}
                onChange={handleInputChange}
                style={styles.input}
              >
                <option value="">Select a team member</option>
                {team.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.name}
                  </option>
                ))}
                 {user.role === 'Member' && (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                )}
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="reportedTo">
                Reported To:
              </label>
              <select
                name="reportedTo"
                value={taskData.reportedTo}
                onChange={handleInputChange}
                style={styles.input}
              >
                <option value="">Select a team member</option>
                {allTeam.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.name}
                  </option>
                ))}
                 {user.role === 'Member' && (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                )}
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="client">
                Client:
              </label>
              <select
                name="client"
                value={taskData.client}
                onChange={handleInputChange}
                style={styles.input}
              >
                <option value="">Select a client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
            <div style={styles.formActions}>
              <button onClick={handleUpdateTask} style={styles.saveButton}>
                Save
              </button>
              <button
                onClick={() => setEditModalOpen(false)}
                style={styles.closeButton}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}


{filteredTasks.length > 0 ? (
        <TaskTable tasks={filteredTasks} loadTasks={loadTasks} onEdit={handleEditTask} />
      ) : (
        <div style={styles.noTasksContainer}>
          <h3>No tasks available</h3>
          <p>Please add a task to get started.</p>
        </div>
      )}
    </div>
  );
};
const TaskTable = ({ tasks,loadTasks, onEdit}) => {
  const handleStatusChange = async (taskId, newStatus,dropdown) => {
    try {
      // Send PATCH request to update the task status
      const response = await axios.patch(
        `https://backend.promptitude.deloai.com/tasks/${taskId}/status`,
        { status: newStatus }
      );
      console.log("Task status updated successfully", response.data);
      // You may need to reload the tasks to see the updated status or modify the state directly
      dropdown.style.display = "none";

      loadTasks();
    } catch (error) {
      console.error("Failed to update task status", error);
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Pending":
        return { backgroundColor: "#f0ad4e", color: "#fff" }; // Yellow
      case "In Progress":
        return { backgroundColor: "#5bc0de", color: "#fff" }; // Blue
      case "Completed":
        return { backgroundColor: "#5cb85c", color: "#fff" }; // Green
      case "On Hold":
        return { backgroundColor: "#d9534f", color: "#fff" }; // Red
      default:
        return { backgroundColor: "#777", color: "#fff" }; // Default Gray
    }
  };

  return (
    <table style={styles.table}>
      <thead>
        <tr>
          {["Title", "Description", "Assigned To","Assigned By","Reporting To","Status" ,"Deadline" , "Client","Action"].map(
            (header) => (
              <th key={header} style={styles.th}>
                {header}
              </th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {tasks.map((task, index) => (
          <tr key={index}>
            <td style={styles.td}>{task.title}</td>
            <td style={styles.td}>{task.desc}</td>
            <td style={styles.td}>{task.assigned_to ? task.assigned_to.name : "NA"}</td>
            <td style={styles.td}>{task.assigned_by ? task.assigned_by.name : "NA"}</td>
            <td style={styles.td}>{task.reporting_to ? task.reporting_to.name : "NA"}</td>
            <td style={{ ...styles.td, position: "relative" }}>
              <div
                style={{
                  ...getStatusStyle(task.status),
                  padding: "5px 10px",
                  borderRadius: "12px",
                  textAlign: "center",
                  cursor: task.status === "Completed" ? "default" : "pointer",
                  display: "inline-block",
                  fontSize: "14px",
                }}
                onClick={(e) => {
                  if (task.status !== "Completed") {
                    // Toggle dropdown visibility
                    const dropdown = e.currentTarget.nextSibling;
                    dropdown.style.display =
                      dropdown.style.display === "block" ? "none" : "block";
                  }
                }}
              >
                {task.status}
              </div>
              {task.status !== "Completed" && (
                <div style={{ ...styles.statusDropdownMenu, display: "none" }}>
                  {["Pending", "In Progress", "Completed", "On Hold"].map((status) => (
                    <div
                      key={status}
                      style={styles.dropdownItem}
                      onClick={(e) => {
                        if (window.confirm(`Are you sure you want to change the status to '${status}'?`)) {
                          handleStatusChange(task.id, status,e.currentTarget.parentNode);
                        }
                      }}
                    >
                      {status}
                    </div>
                  ))}
                </div>
              )}
            </td>
            <td
              style={{
                ...styles.td,
                color:
                  new Date(task.deadline) < new Date() &&
                  task.status !== "Completed"
                    ? "red"
                    : "inherit",
              }}
            >
              {(() => {
                const date = new Date(task.deadline);
                const day = date.getDate();
                const month = date.toLocaleString("default", { month: "short" });
                const year = date.getFullYear();
                return `${day} ${month}, ${year}`;
              })()}
            </td>
           
            <td style={styles.td}>{task.client?.name || "NA"}</td>
                        {/* <td style={styles.td}>kamsdksmadasm kas dkasmd asd </td> */}

            <td style={styles.td}>
              <button onClick={() => onEdit(task)} style={styles.editButton}>
                <FaEdit /> Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};



const styles = {
  
  statusDropdownMenu: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "white",
    border: "1px solid #ddd",
    listStyle: "none",
    padding: "0",
    margin: "5px 0 0 0",
    minWidth: "120px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: "1000",
    borderRadius: "5px",
  },
  dropdownItem: {
    padding: "10px",
    cursor: "pointer",
    fontSize: "14px",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
  },
  modal: {
    position: "relative",
    background: "#FFFFFF",
    padding: "30px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
    zIndex: "1000",
    width: "50%",
    maxWidth: "600px",
    height: "auto",
    borderRadius: "12px",
  },
  noTasksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 0",
    color: "#555",
    textAlign: "center",
  },
  closeModalButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "20px",
  },
  modalHeader: {
    color: "#2c2e34",
    marginBottom: "20px",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "bold",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "6px",
    fontSize: "16px",
  },
  formActions: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  saveButton: {
    backgroundColor: "#4CAF50",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
  },
  closeButton: {
    backgroundColor: "#F44336",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "10px",
    transition: "background-color 0.3s ease",
  },
  container: {
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    color: "#2c2e34",
    marginBottom: "12px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  searchWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    position: "absolute",
    left: "10px",
    color: "#aaa",
  },
  searchInput: {
    width: "200px",
    padding: "8px 8px 8px 30px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginRight: "10px",
  },
  addButton: {
    backgroundColor: "#2c2e34",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },
  
  // Table Styles
  table: {
    margin: "0 auto",
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f3f2f2",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  // Dropdown Styles
  dropdownButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "white",
    border: "1px solid #ddd",
    listStyle: "none",
    padding: "0",
    margin: "0",
    minWidth: "120px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: "1000",
    borderRadius: "5px",
  },
  dropdownItem: {
    padding: "8px",
    cursor: "pointer",
  },

};

export default MyTasks;
