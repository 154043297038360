import React, { useState, useEffect } from "react";
import { FaSearch, FaEllipsisV, FaTimes } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
const Clients = () => {
  const [clients, setClients] = useState([]);
  const [team, setTeam] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const newClientTemplate = {
    name: "",
    email: "",
    company_type: "",
    manager: "",
    PAN: "",
    CIN: "",
    GSTIN: "",
    phno: "",
    city: "",
    state: "",
    turnover: "",
  };
  const [newClient, setNewClient] = useState(newClientTemplate);

  useEffect(() => {
    loadClients();
    if (user?.role === "Admin") {
      loadTeamMembers();
    }
  }, []);
  

  const loadClients = async () => {
    try {
      const response = await axios.get("https://backend.promptitude.deloai.com/clients", { params: { user_id: user.id} });      
      // Assuming response.data is an array of client objects
      const updatedClients = response.data.map(client => {
        // Update yearly_compliance
        if (client.yearly_compliance && client.yearly_compliance.length > 0) {
          client.yearly_compliance_subfields = client.yearly_compliance; // Store original list in subfields

          client.yearly_compliance = true;
        } else {
          client.yearly_compliance = false;
        }
  
        // Update monthly_compliance
        if (client.monthly_compliance && client.monthly_compliance.length > 0) {
          client.monthly_compliance_subfields = client.monthly_compliance; // Store original list in subfields

          client.monthly_compliance = true;
        } else {
          client.monthly_compliance = false;
        }
  
        // Update quarterly_compliance
        if (client.quarterly_compliance && client.quarterly_compliance.length > 0) {
          client.quarterly_compliance_subfields = client.quarterly_compliance; // Store original list in subfields

          client.quarterly_compliance = true;
        } else {
          client.quarterly_compliance = false;
        }
  
        return client; // Return updated client object
      });
  
      // Set the clients state
      setClients(updatedClients);
    } catch (error) {
      console.error("Failed to load clients", error);
    }
  };
  

  const loadTeamMembers = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }
      const response = await axios.get(`https://backend.promptitude.deloai.com/user/users?user_id=${userId}`);
      setTeam(response.data);
    } catch (error) {
      console.error("Failed to load team members", error);
    }
  };

  const handleAddClient = async () => {
    try {
       // Validate mandatory fields
    if (!newClient.name) {
      alert("Name is required");
      return;
    }
    if (!newClient.manager) {
      alert("Manager is required");
      return;
    }
   
    if (!newClient.company_type) {
      alert("Company Type is required");
      return;
    }
    if (!newClient.phno) {
      alert("Phone Number is required");
      return;
    }
    if (user.role === "Admin" && !newClient.manager) {
      alert("Manager is required for Admin");
      return;
    }

      if(user.role ==='Member'){
        newClient.manager = user.id
      }
      console.log(newClient)
      await axios.post("https://backend.promptitude.deloai.com/clients", newClient);
      loadClients();
      setAddModalOpen(false);
      setNewClient(newClientTemplate);
    } catch (error) {
      console.error("Error adding client:", error);
      alert(`Failed to add client: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleUpdateClient = async (updatedClient) => {
    try {
      console.log(updatedClient);
 // Create a copy of the updated client and remove the tasks property
 const clientToUpdate = { ...updatedClient };
 delete clientToUpdate.tasks;
 if(!updatedClient.manager){
    alert("Please assign  a manager")
    return 
  }

 console.log(clientToUpdate);
 await axios.put(`https://backend.promptitude.deloai.com/clients/${clientToUpdate.id}`, clientToUpdate);     
  loadClients();
      setUpdateModalOpen(false);
    } catch (error) {
      console.error("Error updating client:", error);
      alert(`Failed to update client: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      if (window.confirm("Are you sure you want to delete this client?")) {
        await axios.delete(`https://backend.promptitude.deloai.com/clients/${clientId}`);
        loadClients();
      }
    } catch (error) {
      console.error("Error deleting client:", error);
      alert(`Failed to delete client: ${error.response?.data?.message || error.message}`);
    }
  };

  const filteredClients = clients.filter((client) =>
    [client.name, client.company_type].some((field) =>
      field?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Clients</h1>
      <div style={styles.searchContainer}>
        <div style={styles.searchWrapper}>
          <FaSearch style={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search by name or company type..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={styles.searchInput}
          />
        </div>
        <button onClick={() => setAddModalOpen(true)} style={styles.addButton}>
          Add Client
        </button>
      </div>
      { filteredClients.length>0 ?(
      <ClientTable
        clients={filteredClients}
        onEdit={(client) => {
          setSelectedClient({...client,manager:client.manager? client.manager.id : ''});
          setUpdateModalOpen(true);
        }}
        onDelete={handleDeleteClient}
        toggleDropdown={toggleDropdown}
        openDropdown={openDropdown}
      />)
      :
      <div style={styles.noTasksContainer}>
          <h3>No Clients available</h3>
          <p>Please add a task to get started.</p>
        </div>

}
      {isAddModalOpen && (
        <ClientModal
          title="Add New Client"
          client={newClient}
          setClient={setNewClient}
          onClose={() => setAddModalOpen(false)}
          onSave={handleAddClient}
          team={team}
          user={user}
        />
      )}
      {isUpdateModalOpen && selectedClient && (
        <ClientModal
          title="Update Client"
          client={selectedClient}
          setClient={setSelectedClient}
          onClose={() => setUpdateModalOpen(false)}
          onSave={() => handleUpdateClient(selectedClient)}
          team={team}
          user={user}
        />
      )}
    </div>
  );
};

const ClientTable = ({ clients, onEdit, onDelete, toggleDropdown, openDropdown }) => (
  <table style={styles.table}>
    <thead>
      <tr>
        {['Name', 'Email', 'Company Type', 'City', 'State', 'Turnover', 'Actions'].map(header => (
          <th key={header} style={styles.th}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {clients.map((client, index) => (
        <tr key={index}>
          <td style={styles.td}>
        {/* Use Link to make the name clickable with improved styling */}
        <Link 
          to={`/client/${client.id}`} 
          style={styles.link}
        >
          {client.name}
        </Link>
      </td>
      
          <td style={styles.td}>{client.email}</td>
          <td style={styles.td}>{client.company_type}</td>
          <td style={styles.td}>{client.city}</td>
          <td style={styles.td}>{client.state}</td>
          <td style={styles.td}>{client.turnover}</td>
          <td style={styles.td}> 
            <div style={{ position: "relative" }}>
              <button onClick={() => toggleDropdown(index)} style={styles.dropdownButton}>
                {openDropdown === index ? <FaTimes /> : <FaEllipsisV />}
              </button>
              {openDropdown === index && (
                <ul style={styles.dropdownMenu}>
                  <li onClick={() => onEdit(client)} style={styles.dropdownItem}>Update</li>
                  <li onClick={() => onDelete(client.id)} style={styles.dropdownItem}>Delete</li>
                </ul>
              )}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

// const ClientModal = ({ title, client, setClient, onClose, onSave, team, user }) => (
//   <div style={styles.modal}>
//     <h2 style={styles.modalHeader}>{title}</h2>
//     <form
//       onSubmit={(e) => {
//         e.preventDefault();
//         onSave();
//       }}
//     >
//       {/* Form Fields */}
//       <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
//         <div style={{ flex: 1 }}>
//           <label>Name:</label>
//           <input
//             type="text"
//             value={client.name}
//             onChange={(e) => setClient({ ...client, name: e.target.value })}
//             style={styles.input}
//           />
//         </div>
//         <div style={{ flex: 1 }}>
//           <label>Email:</label>
//           <input
//             type="email"
//             value={client.email}
//             onChange={(e) => setClient({ ...client, email: e.target.value })}
//             style={styles.input}
//           />
//         </div>
//       </div>
//       <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
//         <div style={{ flex: 1 }}>
//           <label>Company Type:</label>
//           <select
//             value={client.company_type}
//             onChange={(e) => setClient({ ...client, company_type: e.target.value })}
//             style={styles.input}
//           >
//             <option value="">Select Company Type</option>
//             <option value="Private Limited">Private Limited</option>
//             <option value="Public Limited">Public Limited</option>
//             <option value="Partnership Firm">Partnership Firm</option>
//             <option value="Proprietorship">Proprietorship</option>
//             <option value="Limited Liability Partnership">Limited Liability Partnership</option>
//             <option value="One Person Company">One Person Company</option>
//             <option value="Individual">Individual</option>
//             <option value="Co-operative Society">Co-operative Society</option>
//             <option value="Trust">Trust</option>
//           </select>
//         </div>
//         {user.role === 'Admin' && (
//           <div style={{ flex: 1 }}>
//             <label>Manager:</label>
//             <select
//               value={client.manager}
//               onChange={(e) => setClient({ ...client, manager: e.target.value })}
//               style={styles.input}
//             >
//               <option value="">Select Manager</option>
//               {team.map((member) => (
//                 <option key={member.id} value={member.id}>{member.name}</option>
//               ))}
//             </select>
//           </div>
//         )}
//       </div>
//       <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
//         <div style={{ flex: 1 }}>
//           <label>PAN:</label>
//           <input
//             type="text"
//             value={client.PAN}
//             onChange={(e) => setClient({ ...client, PAN: e.target.value })}
//             style={styles.input}
//           />
//         </div>
//         <div style={{ flex: 1 }}>
//           <label>CIN:</label>
//           <input
//             type="text"
//             value={client.CIN}
//             onChange={(e) => setClient({ ...client, CIN: e.target.value })}
//             style={styles.input}
//           />
//         </div>
//         <div style={{ flex: 1 }}>
//           <label>GSTIN:</label>
//           <input
//             type="text"
//             value={client.GSTIN}
//             onChange={(e) => setClient({ ...client, GSTIN: e.target.value })}
//             style={styles.input}
//           />
//         </div>
//       </div>
//       <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
//         <div style={{ flex: 1 }}>
//           <label>Phone Number:</label>
//           <input
//             type="text"
//             value={client.phno}
//             onChange={(e) => setClient({ ...client, phno: e.target.value })}
//             style={styles.input}
//           />
//         </div>
//         <div style={{ flex: 1 }}>
//           <label>City:</label>
//           <input
//             type="text"
//             value={client.city}
//             onChange={(e) => setClient({ ...client, city: e.target.value })}
//             style={styles.input}
//           />
//         </div>
//         <div style={{ flex: 1 }}>
//           <label>State:</label>
//           <input
//             type="text"
//             value={client.state}
//             onChange={(e) => setClient({ ...client, state: e.target.value })}
//             style={styles.input}
//           />
//         </div>
//       </div>
//       <div style={{ marginBottom: "10px" }}>
//         <label>Turnover:</label>
//         <input
//           type="number"
//           value={client.turnover}
//           onChange={(e) => setClient({ ...client, turnover: e.target.value })}
//           style={styles.input}
//         />
//       </div>
//       <div style={styles.formActions}>
//         <button type="submit" style={styles.saveButton}>Save</button>
//         <button type="button" onClick={onClose} style={styles.closeButton}>Close</button>
//       </div>
//     </form>
//   </div>
// );

const ClientModal = ({ title, client, setClient, onClose, onSave, team, user }) => {
  const handleComplianceChange = (complianceType, value) => {
    setClient({ ...client, [complianceType]: value });
  };

  const handleSubfieldChange = (complianceType, subfield, checked) => {
    const updatedSubfields = checked
      ? [...(client[complianceType + "_subfields"] || []), subfield]
      : client[complianceType + "_subfields"].filter((item) => item !== subfield);
    setClient({ ...client, [complianceType + "_subfields"]: updatedSubfields });
  };

  const complianceCheckboxes = (complianceType, subfields) => (
    <div style={styles.complianceSection}>
      <label style={styles.complianceLabel}>
        <input
          type="checkbox"
          checked={client[complianceType] || false}
          onChange={(e) => handleComplianceChange(complianceType, e.target.checked)}
        />
        {complianceType.replace('_', ' ')}
      </label>
      {client[complianceType] && (
        <div style={styles.subfieldsContainer}>
          {subfields.map((subfield, index) => (
            <div key={index} style={styles.subfieldItem}>
              <label style={styles.subfieldLabel}>
                <input
                  type="checkbox"
                  checked={client[complianceType + "_subfields"]?.includes(subfield) || false}
                  onChange={(e) => handleSubfieldChange(complianceType, subfield, e.target.checked)}
                />
                {subfield}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div style={styles.modal}>
      <h2 style={styles.modalHeader}>{title}</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        {/* Existing Form Fields */}
        <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div style={{ flex: 1 }}>
           <label>Name:</label>
           <input
            type="text"
            value={client.name}
            onChange={(e) => setClient({ ...client, name: e.target.value })}
            style={styles.input}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label>Email:</label>
          <input
            type="email"
            value={client.email}
            onChange={(e) => setClient({ ...client, email: e.target.value })}
            style={styles.input}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        <div style={{ flex: 1 }}>
          <label>Company Type:</label>
          <select
            value={client.company_type}
            onChange={(e) => setClient({ ...client, company_type: e.target.value })}
            style={styles.input}
          >
            <option value="">Select Company Type</option>
            <option value="Private Limited">Private Limited</option>
            <option value="Public Limited">Public Limited</option>
            <option value="Partnership Firm">Partnership Firm</option>
            <option value="Proprietorship">Proprietorship</option>
            <option value="Limited Liability Partnership">Limited Liability Partnership</option>
            <option value="One Person Company">One Person Company</option>
            <option value="Individual">Individual</option>
            <option value="Co-operative Society">Co-operative Society</option>
            <option value="Trust">Trust</option>
          </select>
        </div>
        {user.role === 'Admin' && (
          <div style={{ flex: 1 }}>
            <label>Manager:</label>
            <select
              value={client.manager}
              onChange={(e) => setClient({ ...client, manager: e.target.value })}
              style={styles.input}
            >
              <option value="">Select Manager</option>
              {team.map((member) => (
                <option key={member.id} value={member.id}>{member.name}</option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        <div style={{ flex: 1 }}>
          <label>PAN:</label>
          <input
            type="text"
            value={client.PAN}
            onChange={(e) => setClient({ ...client, PAN: e.target.value })}
            style={styles.input}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label>CIN:</label>
          <input
            type="text"
            value={client.CIN}
            onChange={(e) => setClient({ ...client, CIN: e.target.value })}
            style={styles.input}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label>GSTIN:</label>
          <input
            type="text"
            value={client.GSTIN}
            onChange={(e) => setClient({ ...client, GSTIN: e.target.value })}
            style={styles.input}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        <div style={{ flex: 1 }}>
          <label>Phone Number:</label>
          <input
            type="text"
            value={client.phno}
            onChange={(e) => setClient({ ...client, phno: e.target.value })}
            style={styles.input}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label>City:</label>
          <input
            type="text"
            value={client.city}
            onChange={(e) => setClient({ ...client, city: e.target.value })}
            style={styles.input}
          />
        </div>
        <div style={{ flex: 1 }}>
          <label>State:</label>
          <input
            type="text"
            value={client.state}
            onChange={(e) => setClient({ ...client, state: e.target.value })}
            style={styles.input}
          />
        </div>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>Turnover:</label>
        <input
          type="number"
          value={client.turnover}
          onChange={(e) => setClient({ ...client, turnover: e.target.value })}
          style={styles.input}
        />
       </div>

        {/* Compliance Checkboxes */}
        <div style={styles.complianceContainer}>
          {complianceCheckboxes("yearly_compliance", [
            "PT Payment Yearly",
            "Form DPT 3 for FY 2023-24 for Companies",
            "IEC Code Renewal",
            "Income Tax Returns for Individuals",
            "AOC 4 Filing",
            "MGT7 Filing",
            "Audit Report in Form 3CB-3CD and 3CA-3CD",
            "DIR 3 KYC for Directors of Company's and LLP's",
            "AOC 4 Filing for Companies",
            "Form 8 Filing for LLP's",
            "Form 4 LLP",
            "Income Tax Returns for Non-Corporates needing Audit and Corporates",
            "LUT Generation",
          ])}
          {complianceCheckboxes("monthly_compliance", [
            "TDS Payment",
            "GST R1",
            "GST 3B",

            "Professional Tax (PT) on Salaries",
            "Provident Fund (PF) & ESI Returns Payment",
            "Monthly Income Statement",
          ])}
          {complianceCheckboxes("quarterly_compliance", [
            "TDS Returns",
            "Advance Tax",
            "GST R1",
            "GST 3B",
          ])}
        </div>

        <div style={styles.formActions}>
          <button type="submit" style={styles.saveButton}>Save</button>
          <button type="button" onClick={onClose} style={styles.closeButton}>Close</button>
        </div>
      </form>
    </div>
  );
};
const styles = {
  // Modal Styles
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    padding: "30px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
    zIndex: "1000",
    width: "50%",
    maxWidth: "800px",
    height: "70%",
    borderRadius: "12px",
    overflowY: "auto",
  },
  modalHeader: {
    color: "#2c2e34",
    marginBottom: "20px",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
  },

  // General Container Styles
  container: {
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    color: "#2c2e34",
    marginBottom: "12px",
  },

  // Search Container Styles
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  searchWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    position: "absolute",
    left: "10px",
    color: "#aaa",
  },
  searchInput: {
    width: "200px",
    padding: "8px 8px 8px 30px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginRight: "10px",
  },

  // Button Styles
  addButton: {
    backgroundColor: "#2c2e34",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },
  saveButton: {
    backgroundColor: "#4CAF50",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
  },
  closeButton: {
    backgroundColor: "#F44336",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "10px",
    transition: "background-color 0.3s ease",
  },

  // Label & Input Styles
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "bold",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "6px",
    fontSize: "16px",
  },

  link: {
    textDecoration: 'none',
    color: '#666666', // Gives it a blue color similar to hyperlinks
    cursor: 'pointer', // Shows the pointer to indicate it's clickable
    transition: 'color 0.3s', // Smooth transition for the color on hover
  },
  noTasksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 0",
    color: "#555",
    textAlign: "center",
  },
  linkHover: {
    textDecoration: 'underline', // Underline appears on hover to indicate interactivity
  },
  // Table Styles
  table: {
    margin: "0 auto",
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f3f2f2",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },

  // Dropdown Styles
  dropdownButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "white",
    border: "1px solid #ddd",
    listStyle: "none",
    padding: "0",
    margin: "0",
    minWidth: "120px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: "1000",
    borderRadius: "5px",
  },
  dropdownItem: {
    padding: "8px",
    cursor: "pointer",
  },

  // Compliance Section Styles
  complianceContainer: {
    marginBottom: "20px",
  },
  complianceSection: {
    marginBottom: "15px",
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  complianceLabel: {
    fontWeight: "600",
    color: "#444",
    display: "block",
    marginBottom: "10px",
  },
  subfieldsContainer: {
    paddingLeft: "15px",
    marginTop: "10px",
  },
  subfieldItem: {
    marginBottom: "8px",
  },
  subfieldLabel: {
    color: "#555",
  },

  // Form Action Styles
  formActions: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  formGroup: {
    marginBottom: "10px",
  },
};

export default Clients;