import React, { useState, useEffect } from "react";
import { FaSearch, FaTimes, FaPlusCircle } from "react-icons/fa";
import axios from "axios";

const MyLeaves = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [leaveData, setLeaveData] = useState({
    startDate: "",
    endDate: "",
    reason: "",
    leaveType: "",
  });
  const [leaves, setLeaves] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  
  useEffect(() => {
    loadLeaves();
  }, []);

  const loadLeaves = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }
      const response = await axios.get(`https://backend.promptitude.deloai.com/leaves?user_id=${userId}`);
      console.log(response.data)
      setLeaves(response.data);
    } catch (error) {
      console.error("Failed to load leaves", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLeaveData({ ...leaveData, [name]: value });
  };

  const handleApplyLeave = async () => {
    try {
      if (!leaveData.startDate || !leaveData.endDate || !leaveData.reason || !leaveData.leaveType) {
        alert("Please fill out all required fields.");
        return;
      }
      console.log({
        ...leaveData,
        user_id: user.id,
      })
      const response = await axios.post("https://backend.promptitude.deloai.com/leaves", {
        ...leaveData,
        user_id: user.id,
      });

      console.log("Leave applied successfully", response.data);
      setAddModalOpen(false);
      setLeaveData({ startDate: "", endDate: "", reason: "", leaveType: "" });
      loadLeaves();
    } catch (error) {
      console.error("Error applying for leave", error);
    }
  };

  const filteredLeaves = leaves.filter((leave) =>
    [leave.reason, leave.leaveType].some((field) =>
      field?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>My Leaves</h1>
      <div style={styles.searchContainer}>
        <div style={styles.searchWrapper}>
          <FaSearch style={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search by leave reason or type..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={styles.searchInput}
          />
        </div>
        <button onClick={() => setAddModalOpen(true)} style={styles.addButton}>
          <FaPlusCircle /> Apply for Leave
        </button>
      </div>

      {isAddModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <div style={styles.modalHeader}>Apply for Leave</div>
            <button
              style={styles.closeModalButton}
              onClick={() => setAddModalOpen(false)}
            >
              <FaTimes />
            </button>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="startDate">
                Start Date:
              </label>
              <input
                type="date"
                name="startDate"
                value={leaveData.startDate}
                onChange={handleInputChange}
                style={styles.input}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="endDate">
                End Date:
              </label>
              <input
                type="date"
                name="endDate"
                value={leaveData.endDate}
                onChange={handleInputChange}
                style={styles.input}
                min={leaveData.startDate || new Date().toISOString().split("T")[0]}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="leaveType">
                Leave Type:
              </label>
              <select
                name="leaveType"
                value={leaveData.leaveType}
                onChange={handleInputChange}
                style={styles.input}
              >
                <option value="">Select a leave type</option>
                <option value="Sick Leave">Sick Leave</option>
                <option value="Casual Leave">Casual Leave</option>
                <option value="Paid Leave">Paid Leave</option>
              </select>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label} htmlFor="reason">
                Reason:
              </label>
              <textarea
                name="reason"
                value={leaveData.reason}
                onChange={handleInputChange}
                style={{ ...styles.input, height: "100px" }}
              />
            </div>
            <div style={styles.formActions}>
              <button onClick={handleApplyLeave} style={styles.saveButton}>
                Apply
              </button>
              <button
                onClick={() => setAddModalOpen(false)}
                style={styles.closeButton}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {filteredLeaves.length > 0 ? (
        <LeaveTable leaves={filteredLeaves} />
      ) : (
        <div style={styles.noLeavesContainer}>
          <h3>No leaves available</h3>
          <p>Please apply for leave to get started.</p>
        </div>
      )}
    </div>
  );
};

const LeaveTable = ({ leaves }) => {
  const getStatusStyle = (status) => {
    switch (status) {
      case "Approved":
        return { backgroundColor: "#5cb85c", color: "#fff" }; // Green
      case "Pending":
        return { backgroundColor: "#f0ad4e", color: "#fff" }; // Yellow
      case "Rejected":
        return { backgroundColor: "#d9534f", color: "#fff" }; // Red
      default:
        return { backgroundColor: "#777", color: "#fff" }; // Default Gray
    }
  };

  return (
    <table style={styles.table}>
      <thead>
        <tr>
          {["Start Date", "End Date", "Leave Type", "Reason", "Status"].map(
            (header) => (
              <th key={header} style={styles.th}>
                {header}
              </th>
            )
          )}
        </tr>
      </thead>
      <tbody>
        {leaves.map((leave, index) => (
          <tr key={index}>
            <td style={styles.td}>
              {new Date(leave.start_date).toLocaleDateString()}
            </td>
            <td style={styles.td}>
              {new Date(leave.end_date).toLocaleDateString()}
            </td>
            <td style={styles.td}>{leave.leave_type}</td>
            <td style={styles.td}>{leave.reason}</td>
            <td style={{ ...styles.td, textAlign: "center" }}>
              <div
                style={{
                  ...getStatusStyle(leave.status),
                  padding: "5px 10px",
                  borderRadius: "12px",
                  fontSize: "14px",
                }}
              >
                {leave.status}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    color: "#2c2e34",
    marginBottom: "12px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  searchWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    position: "absolute",
    left: "10px",
    color: "#aaa",
  },
  searchInput: {
    width: "200px",
    padding: "8px 8px 8px 30px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    marginRight: "10px",
  },
  addButton: {
    backgroundColor: "#2c2e34",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
  },
  modal: {
    position: "relative",
    background: "#FFFFFF",
    padding: "30px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.3)",
    zIndex: "1000",
    width: "50%",
    maxWidth: "600px",
    height: "auto",
    borderRadius: "12px",
  },
  modalHeader: {
    color: "#2c2e34",
    marginBottom: "20px",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "bold",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "6px",
    fontSize: "16px",
  },
  formActions: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  saveButton: {
    backgroundColor: "#4CAF50",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
  },
  closeButton: {
    backgroundColor: "#F44336",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "8px",
    padding: "12px 25px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "10px",
    transition: "background-color 0.3s ease",
  },
  noLeavesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 0",
    color: "#555",
    textAlign: "center",
  },
  table: {
    margin: "0 auto",
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f3f2f2",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },
};

export default MyLeaves;
