import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import UserDetails from "./Pages/Teams/UserDetails";
import Dashboard from "./Pages/Dashboard";
import Tasks from "./Pages/Tasks/Tasks";
import Clients from "./Pages/Clients";
import Reminder from "./Pages/Reminder";
import MyAccount from "./Pages/MyAccount";
import Members from "./Pages/Teams/Members";
import Login from "./Pages/Login";
import ProtectedRoute from "./components/ProctedRoute";
import MonthlyCompliancePage from "./Pages/MonthlyCompliancePage";
import "./App.css";
import ClientDetail from "./Pages/ClientDetail";
import TodayTasks from "./Pages/Tasks/TodayTasks";
import ReportedTasks from "./Pages/Tasks/Reported";
import MyTasks from "./Pages/Tasks/MyTasks";
import Profile from "./Pages/Teams/Profile";
import MyLeaves from "./Pages/Leaves/MyLeaves";
import QuarterlyCompliancePage from "./Pages/QuarterlyCompliancePage";
import YearlyCompliancePage from "./Pages/YearlyCompliance";
import ManageLeaves from "./Pages/Teams/ManageLeaves.js";
import Notifications from "./Pages/Notification.js";
import Logs from "./Pages/Logs.js";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

 

  // Determine if the current route is the login page
  const isNavBar = location.pathname === "/login";

  return (
    <div className="app-container">
      {!isNavBar && <Header />}
      <div className="content-container">
        {!isNavBar && <Navbar />}
        <div className="main-screen">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tasks/all"
              element={
                <ProtectedRoute>
                  <Tasks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tasks/today"
              element={
                <ProtectedRoute>
                  <TodayTasks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tasks/assigned_to_me"
              element={
                <ProtectedRoute>
                  <MyTasks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tasks/reporting_to_me"
              element={
                <ProtectedRoute>
                  <ReportedTasks />
                </ProtectedRoute>
              }
            />
            <Route
              path="/logs"
              element={
                <ProtectedRoute>
                  <Logs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <ProtectedRoute>
                  <Clients />
                </ProtectedRoute>
              }
            />
            <Route
              path="/monthly/:complianceType"
              element={
                <ProtectedRoute>
                  <MonthlyCompliancePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/yearly/:complianceType"
              element={
                <ProtectedRoute>
                  <YearlyCompliancePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/quarterly/:complianceType"
              element={
                <ProtectedRoute>
                  <QuarterlyCompliancePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/client/:client_id"
              element={
                <ProtectedRoute>
                  <ClientDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/:user_id"
              element={
                <ProtectedRoute>
                  <UserDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-account/leaves"
              element={
                <ProtectedRoute>
                  <MyLeaves />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-account/notifications"
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reminder"
              element={
                <ProtectedRoute>
                  <Reminder />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-account"
              element={
                <ProtectedRoute>
                  <MyAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-teams/members"
              element={
                <ProtectedRoute>
                  <Members />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-teams/leaves"
              element={
                <ProtectedRoute>
                  <ManageLeaves />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
