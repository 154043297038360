import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function ComplianceNavbar({ complianceType }) {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  // Navbar Configuration for Monthly, Quarterly, and Yearly Compliance
  const complianceMenus = {
    monthly: [
      { to: '/monthly/tds-payment', label: 'TDS Payment' },
      { to: '/monthly/gst-r1', label: 'GST R1' },
      { to: '/monthly/gst-3b', label: 'GST 3B' },
      { to: '/monthly/pt-salaries', label: 'Professional Tax (PT) on Salaries' },
      { to: '/monthly/pf-esi-payment', label: 'Provident Fund (PF) & ESI Returns Payment' },
      { to: '/monthly/monthly-income-statement', label: 'Monthly Income Statement' },

    ],
    quarterly: [
      { to: '/quarterly/tds-returns', label: 'TDS Returns' },
      { to: '/quarterly/advance-tax', label: 'Advance Tax' },
      { to: '/quarterly/gstr1', label: 'GST R1' },
      { to: '/quarterly/gst-3b', label: 'GST 3B' },
    ],
    yearly: [
      { to: '/yearly/pt-payment', label: 'PT Payment Yearly' },
      { to: '/yearly/form-dpt3', label: 'Form DPT 3 for FY 2023-24 for Companies' },
      { to: '/yearly/iec-renewal', label: 'IEC Code Renewal' },
      { to: '/yearly/it-returns-individuals', label: 'Income Tax Returns for Individuals' },
      { to: '/yearly/aoc4-filing', label: 'AOC 4 Filing' },
      { to: '/yearly/mgt7-filing', label: 'MGT7 Filing' },
      { to: '/yearly/audit-report', label: 'Audit Report in Form 3CB-3CD and 3CA-3CD' },
      { to: '/yearly/dir3-kyc', label: "DIR 3 KYC for Directors of Company's and LLP's" },
      { to: '/yearly/aoc4-companies', label: 'AOC 4 Filing for Companies' },
      { to: '/yearly/form8-llp', label: "Form 8 Filing for LLP's" },
      { to: '/yearly/form4-llp', label: 'Form 4 LLP' },
      { to: '/yearly/it-returns-audit', label: 'Income Tax Returns for Non-Corporates needing Audit and Corporates' },
      { to: '/yearly/lut-generation', label: 'LUT Generation' },
    ],
  };

  // Determine the menus to display based on complianceType prop
  const linksToDisplay = complianceMenus[complianceType] || [];

  // Capitalize compliance type for heading
  const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);

  return (
    <div className="navbar">
      <h2 className="navbar-heading">{capitalize(complianceType)} Compliance</h2>
      <ul>
        {linksToDisplay.map((link) => (
          <li key={link.to} className={`heading ${isActive(link.to) ? 'active' : ''}`}>
            <Link to={link.to}>{link.label}</Link>
          </li>
        ))}
         {/* Back to Dashboard link */}
         <li key="dashboard" className={`heading ${isActive('/') ? 'active' : ''}`}>
          <Link to="/">Back to Dashboard</Link>
        </li>
      </ul>
    </div>
  );
}

export default ComplianceNavbar;
