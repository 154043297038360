import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  FaTasks,
  FaUserFriends,
  FaBell,
  FaUsers,
  FaUser,
  FaChevronUp,
  FaChevronDown,
  FaBook
} from 'react-icons/fa';
import ComplianceNavbar from './ComplianceNavBar';
import './Navbar.css';
import axios from 'axios';  // Import axios for API calls

function Navbar() {
  const [activeMenu, setActiveMenu] = useState(null);
  const [userRole, setUserRole] = useState(null); // State for storing user role

  const [unreadNotifications, setUnreadNotifications] = useState(0); // State for unread notifications
  const location = useLocation(); // Get the current route location
  const { complianceType } = useParams(); // Get the compliance type from the route params

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user && user.role) {
      setUserRole(user.role); // Set the user role from sessionStorage
    }

    fetchUnreadNotificationsCount(); // Fetch unread notifications count when component mounts
  }, []);

  const fetchUnreadNotificationsCount = async () => {
    try {
      // Fetch the unread notifications count from the backend
      const userId = JSON.parse(sessionStorage.getItem("user"))?.id; // Assuming user is stored in sessionStorage
      const response = await axios.get(`https://backend.promptitude.deloai.com/notifications/unread-count`, {
        params: { user_id: userId },
      });

      setUnreadNotifications(response.data.unread_count); // Set unread count in state
    } catch (error) {
      console.error("Failed to fetch unread notifications count:", error);
    }
  };

  const toggleDropdown = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  const handleNonDropdownClick = () => {
    setActiveMenu(null);
  };

  // Determine if the current route matches any of the menu items
  const isActive = (path) => location.pathname === path;

  const menuItems = [
    {
      key: 'tasks',
      icon: <FaTasks className="icon" />,
      label: 'Tasks',
      links: [
        { to: '/tasks/today', label: 'Today' },
        { to: '/tasks/assigned_to_me', label: 'Assigned to Me' },
        { to: '/tasks/reporting_to_me', label: 'Reporting to Me' },
        { to: '/tasks/all', label: 'All Tasks' },
      ],
    },
    {
      key: 'compliance',
      icon: <FaTasks className="icon" />,
      label: 'Compliance',
      links: [
        { to: '/monthly/tds-payment', label: 'Monthly' },
        { to: '/quarterly/tds-returns', label: 'Quarterly' },
        { to: '/yearly/pt-payment', label: 'Yearly' },
      ],
    },
    
    {
      key: 'myTeams',
      icon: <FaUsers className="icon" />,
      label: 'My Teams',
      links: [
        { to: '/my-teams/members', label: 'Members' },
        { to: '/my-teams/leaves', label: 'Leaves' },
      ],
      
    },
    {
      key: 'myAccount',
      icon: <FaUser className="icon" />,
      label: `My Account${unreadNotifications > 0 ? ` (${unreadNotifications})` : ''}`,
      links: [
        { to: '/my-account/notifications', label: `Notifications${unreadNotifications > 0 ? ` (${unreadNotifications})` : ''}`
      },  // Display unread count
        { to: '/my-account/leaves', label: 'Leaves' },
      ],
    },
  ];
// Only add "Logs" menu item if the user is an Admin
if (userRole === "Admin") {
  menuItems.push({
    key: "logs",
    icon: <FaBook className="icon" />,
    label: "Logs",
    links: [
      { to: "/logs", label: "View Logs" },
    ],
  });
}
  // Check if the current route is for a compliance page (monthly, quarterly, yearly)
  if (location.pathname.startsWith('/monthly') || location.pathname.startsWith('/quarterly') || location.pathname.startsWith('/yearly')) {
    return <ComplianceNavbar complianceType={location.pathname.split('/')[1]} />;
  }

  return (
    <div className="navbar">
      <ul>
      <li className={`heading ${isActive('/') ? 'active' : ''}`} onClick={handleNonDropdownClick}>
          <div>
            <FaBell className="icon" />
            <Link to="/">Dashboard</Link>
          </div>
        </li>
        {/* Clients Link - No dropdown */}
        <li className={`heading ${isActive('/clients') ? 'active' : ''}`} onClick={handleNonDropdownClick}>
          <div>
            <FaUserFriends className="icon" />
            <Link to="/clients">Clients</Link>
          </div>
        </li>

        {/* Dropdown Menu Items */}
        {menuItems.map((menuItem) => (
          <React.Fragment key={menuItem.key}>
            <li className={`heading ${activeMenu === menuItem.key ? 'active' : ''}`} onClick={() => toggleDropdown(menuItem.key)}>
              <div>
                {menuItem.icon} {menuItem.label}
              </div>
              {activeMenu === menuItem.key ? <FaChevronUp /> : <FaChevronDown />}
            </li>
            {activeMenu === menuItem.key && (
              <ul className="dropdown">
                {menuItem.links.map((link) => (
                  <li key={link.to} className={`subheading ${isActive(link.to) ? 'active' : ''}`}>
                    <Link to={link.to}>{link.label}</Link>
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        ))}

       
      </ul>
    </div>
  );
}

export default Navbar;
