import React from 'react';

function Reminder() {
  return (
    <div>
      <h1>Reminder</h1>
    </div>
  );
}

export default Reminder