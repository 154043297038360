import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { FaSearch, FaEllipsisV, FaTimes } from "react-icons/fa";

const Profile = () => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
   
  const  user_id  = user.id;
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
    const [clients ,setClients] = useState([]);
    const [tasks,setTasks] =useState([]);
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`https://backend.promptitude.deloai.com/user/${user_id}`);
      setUserDetails(response.data);
      setLoading(false);
    } catch (err) {
      setError('User not found or an error occurred while fetching data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    loadClients();
    loadTasks()

  }, [user_id]);
  const handleUpdatePasswordChange = async (user) => {
    if (!newPassword || !confirmPassword) {
      setError("Password fields cannot be blank.*");
      return;
    }
    console.log(user)
    console.log(newPassword)
    // Check if user object and new password are provided
    if (!user || !newPassword) {
      setError("User object or new password is missing");

      console.error("User object or new password is missing");
      return;
    }
  
    // const { id, newPassword } = user;
    if (newPassword === confirmPassword) {
    try {
      // Send PUT request to update the password
      const response = await fetch(`https://backend.promptitude.deloai.com/user/${user.id}/update_password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ new_password: newPassword }),
      });
  
      // Parse the response
      const data = await response.json();
  
      // Check if the request was successful
      if (response.ok) {
        alert("Password updated successfully");
        console.log("Password updated:", data);
        setPasswordModalOpen(false)
        setNewPassword('')
        setConfirmPassword('')
      } else {
        alert("Failed to update password: " + data.error);
        console.error("Error updating password:", data.error);
      }
    } catch (error) {
      // Handle any other errors
      alert("An error occurred while updating the password");
      console.error("Error:", error);
    }
  } else {
    setError("Passwords do not match.*");
  }
  };
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);

  const loadClients = async () => {
    try {
      const response = await axios.get("https://backend.promptitude.deloai.com/clients/me", { params: { user_id: user_id} });      
      // Assuming response.data is an array of client objects
      const updatedClients = response.data.map(client => {
        // Update yearly_compliance
        if (client.yearly_compliance && client.yearly_compliance.length > 0) {
          client.yearly_compliance_subfields = client.yearly_compliance; // Store original list in subfields

          client.yearly_compliance = true;
        } else {
          client.yearly_compliance = false;
        }
  
        // Update monthly_compliance
        if (client.monthly_compliance && client.monthly_compliance.length > 0) {
          client.monthly_compliance_subfields = client.monthly_compliance; // Store original list in subfields

          client.monthly_compliance = true;
        } else {
          client.monthly_compliance = false;
        }
  
        // Update quarterly_compliance
        if (client.quarterly_compliance && client.quarterly_compliance.length > 0) {
          client.quarterly_compliance_subfields = client.quarterly_compliance; // Store original list in subfields

          client.quarterly_compliance = true;
        } else {
          client.quarterly_compliance = false;
        }
  
        return client; // Return updated client object
      });
  
      // Set the clients state
      setClients(updatedClients);
    } catch (error) {
      console.error("Failed to load clients", error);
    }
  };
 
  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };
  const loadTasks = async () => {
    try {
    //   const user_id= user_id;
      if (!user_id) {
        console.error("User ID not found in session");
        return;
      }
      
      // Make a request to the server, passing the user ID as a query parameter
      const response = await axios.get(`https://backend.promptitude.deloai.com/tasks/assigned`, {
        params: {
          user_id: user_id
        }
      });
      
      // Set the tasks from the response
      console.log(response.data)
      setTasks(response.data);
    } catch (error) {
      console.error("Failed to load tasks", error);
    }
  };

  if (loading) {
    return <div style={styles.loadingSpinner}>Loading...</div>;
  }

  if (error) {
    return <div style={styles.errorMessage}>{error}</div>;
  }
 
  return (
    <div style={styles.container}>
             {isPasswordModalOpen && (
        <div className="modal">
          <h2>Change Password</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdatePasswordChange(user);
            }}
          >
            <label>New Password: </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {/* <br /> */}
            <label>Confirm Password: </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {/* <br /> */}
            {error && <p className="error-message">{error}</p>}
            <div className="button-container">
              <button className="update-btn" type="submit">
                Change Password
              </button>
              <button
                className="close-btn"
                type="button"
                onClick={() => setPasswordModalOpen(false)}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      )}
      {userDetails && (
        <div style={styles.gridContainer}>
          <div style={styles.userDetailsPaper}>
            <div style={styles.userDetailsSection}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <h2>Profile</h2>
    <div>
    <button 
  onClick={() => setPasswordModalOpen(true)} 
  style={{ ...styles.button, marginRight: '10px' }}
>
  Change Password
</button>

     
    </div>
  </div>              <div style={styles.userDetailsGrid}>
                <div><strong>Name:</strong> {userDetails.name}</div>
                <div><strong>Email:</strong> {userDetails.email_id}</div>
                <div><strong>Role:</strong> {userDetails.role}</div>
                <div><strong>Phone Number:</strong> {userDetails.ph_no}</div>
                <div><strong>Alternate Phone Number:</strong> {userDetails.alt_ph_no}</div>
                <div><strong>Status:</strong> {userDetails.status ? 'Active': "Inactive"}</div>
                <div><strong>Phone Number:</strong> {userDetails.ph_no}</div>


                {userDetails.reporting_to && (
                  <div><strong>Reporting To:</strong> {userDetails.reporting_to.name}</div>
                )}
              </div>
            </div>
          </div>
          <div style={styles.paper}>
            <div style={styles.tabs}>
              <button style={{ ...styles.tabButton, ...(activeTab === 0 ? styles.activeTabButton : {}) }} onClick={() => handleTabChange(0)}>Clients</button>
              <button style={{ ...styles.tabButton, ...(activeTab === 1 ? styles.activeTabButton : {}) }} onClick={() => handleTabChange(1)}>Tasks</button>
            </div>
            <div style={styles.tabContent}>
              {activeTab === 0 && (
                <div style={{ textAlign: 'left', marginTop: '20px' }}>
                  <h3 style={{ marginBottom: '10px' }}>Clients</h3>
                  {clients.length > 0 ? (
                     <ClientTable
                     clients={clients}
                    
                   />
                  ) : (
                    <p>No clients found.</p>
                  )}
                </div>
              )}
              {activeTab === 1 && (
                <div style={{ textAlign: 'left', marginTop: '20px' }}>
                  <h3 style={{ marginBottom: '10px' }}>Tasks</h3>
                  {userDetails.tasks.length > 0 ? (
                  <TaskTable
                  tasks={tasks}
                  loadTasks={loadTasks}
                />
                  ) : (
                    <p>No tasks found.</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  // Table Styles
  table: {
    margin: "0 auto",
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f3f2f2",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  statusDropdownMenu: {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "white",
    border: "1px solid #ddd",
    listStyle: "none",
    padding: "0",
    margin: "5px 0 0 0",
    minWidth: "120px",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: "1000",
    borderRadius: "5px",
  },
  dropdownItem: {
    padding: "10px",
    cursor: "pointer",
    fontSize: "14px",
  },
  linkHover: {
    textDecoration: 'underline', // Underline appears on hover to indicate interactivity
  },
  link: {
    textDecoration: 'none',
    color: '#666666', // Gives it a blue color similar to hyperlinks
    cursor: 'pointer', // Shows the pointer to indicate it's clickable
    transition: 'color 0.3s', // Smooth transition for the color on hover
  },

  gridContainer: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gap: '20px',
  },
  paper: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  userDetailsPaper: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  userDetailsSection: {
    marginBottom: '20px',
  },
  userDetailsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '10px',
  },
  tabs: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
  },
  tabButton: {
    backgroundColor: '#f1f1f1',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  activeTabButton: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  tabContent: {
    marginTop: '20px',
  },
  loadingSpinner: {
    textAlign: 'center',
    margin: '50px 0',
    fontSize: '24px',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    margin: '50px 0',
  },
  clientList: {
    listStyleType: 'none',
    paddingLeft: '0',
  },
  clientListItem: {
    marginBottom: '5px',
  },
  taskList: {
    listStyleType: 'none',
    paddingLeft: '0',
  },
  taskListItem: {
    marginBottom: '5px',
  },
};
const ClientTable = ({ clients}) => (
    <table style={styles.table}>
      <thead>
        <tr>
          {['Name', 'Email', 'Company Type', 'City', 'State', 'Turnover'].map(header => (
            <th key={header} style={styles.th}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {clients.map((client, index) => (
          <tr key={index}>
            <td style={styles.td}>
          {/* Use Link to make the name clickable with improved styling */}
          <Link 
            to={`/client/${client.id}`} 
            style={styles.link}
          >
            {client.name}
          </Link>
        </td>
        
            <td style={styles.td}>{client.email}</td>
            <td style={styles.td}>{client.company_type}</td>
            <td style={styles.td}>{client.city}</td>
            <td style={styles.td}>{client.state}</td>
            <td style={styles.td}>{client.turnover}</td>
       
          </tr>
        ))}
      </tbody>
    </table>
  );
  const TaskTable = ({ tasks,loadTasks}) => {
    const handleStatusChange = async (taskId, newStatus,dropdown) => {
      try {
        // Send PATCH request to update the task status
        const response = await axios.patch(
          `https://backend.promptitude.deloai.com/tasks/${taskId}/status`,
          { status: newStatus }
        );
        console.log("Task status updated successfully", response.data);
        // You may need to reload the tasks to see the updated status or modify the state directly
        dropdown.style.display = "none";
  
        loadTasks();
      } catch (error) {
        console.error("Failed to update task status", error);
      }
    };
  
    const getStatusStyle = (status) => {
      switch (status) {
        case "Pending":
          return { backgroundColor: "#f0ad4e", color: "#fff" }; // Yellow
        case "In Progress":
          return { backgroundColor: "#5bc0de", color: "#fff" }; // Blue
        case "Completed":
          return { backgroundColor: "#5cb85c", color: "#fff" }; // Green
        case "On Hold":
          return { backgroundColor: "#d9534f", color: "#fff" }; // Red
        default:
          return { backgroundColor: "#777", color: "#fff" }; // Default Gray
      }
    };
  
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            {["Title", "Description", "Assigned To","Assigned By","Reporting To","Status" ,"Deadline" ].map(
              (header) => (
                <th key={header} style={styles.th}>
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr key={index}>
              <td style={styles.td}>{task.title}</td>
              <td style={styles.td}>{task.desc}</td>
              <td style={styles.td}>{task.assigned_to ? task.assigned_to.name : "NA"}</td>
              <td style={styles.td}>{task.assigned_by ? task.assigned_by.name : "NA"}</td>
              <td style={styles.td}>{task.reporting_to ? task.reporting_to.name : "NA"}</td>
              <td style={{ ...styles.td, position: "relative" }}>
                <div
                  style={{
                    ...getStatusStyle(task.status),
                    padding: "5px 10px",
                    borderRadius: "12px",
                    textAlign: "center",
                    cursor: task.status === "Completed" ? "default" : "pointer",
                    display: "inline-block",
                    fontSize: "14px",
                  }}
                  onClick={(e) => {
                    if (task.status !== "Completed") {
                      // Toggle dropdown visibility
                      const dropdown = e.currentTarget.nextSibling;
                      dropdown.style.display =
                        dropdown.style.display === "block" ? "none" : "block";
                    }
                  }}
                >
                  {task.status}
                </div>
                {task.status !== "Completed" && (
                  <div style={{ ...styles.statusDropdownMenu, display: "none" }}>
                    {["Pending", "In Progress", "Completed", "On Hold"].map((status) => (
                      <div
                        key={status}
                        style={styles.dropdownItem}
                        onClick={(e) => {
                          if (window.confirm(`Are you sure you want to change the status to '${status}'?`)) {
                            handleStatusChange(task.id, status,e.currentTarget.parentNode);
                          }
                        }}
                      >
                        {status}
                      </div>
                    ))}
                  </div>
                )}
              </td>
              <td
                style={{
                  ...styles.td,
                  color:
                    new Date(task.deadline) < new Date() &&
                    task.status !== "Completed"
                      ? "red"
                      : "inherit",
                }}
              >
                {(() => {
                  const date = new Date(task.deadline);
                  const day = date.getDate();
                  const month = date.toLocaleString("default", { month: "short" });
                  const year = date.getFullYear();
                  return `${day} ${month}, ${year}`;
                })()}
              </td>
             
              
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
export default Profile;