// User.js
import axios from "axios";
const API_URL = "https://backend.promptitude.deloai.com";
export class User {
  constructor(
    user_id,
    name,
    role,
    phone_number,
    email_id,
    alternate_pho_no,
    status,
    clients,
    reporting_to,
    tasks,
    password
  ) {
    this.user_id = user_id;
    this.name = name;
    this.role = role;
    this.phone_number = phone_number;
    this.email_id = email_id;
    this.alternate_pho_no = alternate_pho_no;
    this.status = status;
    this.clients = clients;
    this.reporting_to = reporting_to;
    this.tasks = tasks;
    this.password = password;
  }

  toggleStatus() {
    this.status = !this.status;
  }
}

// export const teamMembers = [
//   new User(
//     1,
//     "John Doe",
//     "Admin",
//     "123-456-7890",
//     "john@example.com",
//     "111-222-3333",
//     true,
//     ["Client A"],
//     "Jane Smith",
//     ["Task 1"],
//     "hashed_password_1"
//   ),
//   new User(
//     2,
//     "Jane Smith",
//     "Member",
//     "123-456-7891",
//     "jane@example.com",
//     "222-333-4444",
//     true,
//     ["Client B"],
//     "John Doe",
//     ["Task 2"],
//     "hashed_password_2"
//   ),
//   new User(
//     3,
//     "Emily Clark",
//     "Member",
//     "123-456-7896",
//     "emily@example.com",
//     "777-888-9999",
//     true,
//     ["Client F"],
//     "John Doe",
//     ["Task 7"],
//     "hashed_password_6"
//   ),
//   new User(
//     4,
//     "Michael Brown",
//     "Manager",
//     "123-456-7897",
//     "michael@example.com",
//     "888-999-0000",
//     true,
//     ["Client G"],
//     "Jane Smith",
//     ["Task 8", "Task 9"],
//     "hashed_password_7"
//   ),
// ];

// export const fetchTeamMembers = async (userId, role) => {
//   console.log("i a from fetchMem", userId);
//   try {
//     const response = await axios.get(`${API_URL}/users`, {
//       params: { user_id: userId, role: role },
//     });
//     console.log("Team members retrieved successfully:", response.data);
//     return response.data.data; // Return the list of users
//   } catch (error) {
//     console.error("Error fetching team members: from back", error);
//     throw error; // Propagate the error for further handling
//   }
// };
export const fetchTeamMembers = async (userId, role) => {
  try {
    const response = await axios.get(`${API_URL}/users`, {
      params: { user_id: userId, role: role },
    });
    console.log(Array.isArray(response.data.data)); // Should be true
    console.log(response.data.data); // Check the actual structure
    
    // Map the response data to User instances
    const teamMembers = response.data.data.map(
      (user) =>
        new User(
          user._id,
          user.name,
          user.role,
          user.phone_number,
          user.email_id,
          user.alternate_pho_no,
          user.status,
          user.clients,
          user.reporting_to,
          user.tasks,
          user.password
        )
    );
    console.log(teamMembers );
    for (let i = 0; i < teamMembers.length; i++) {
      console.log(teamMembers[i].reporting_to + "i am teamMembers " + i);
    }
    return { data: teamMembers, error: null };
  } catch (error) {
    console.error("Error fetching team members:", error);
    return { data: null, error: "Failed to fetch team members." };
  }
};

// Function to update a user
export const updateUser = (team, updatedUser) => {
  console.log("from uodateuser");
  console.log(team);
  return team.map((u) => (u.user_id === updatedUser.user_id ? updatedUser : u));
};

// Function to change a user's password
export const changePassword = (team, userId, newPassword) => {
  console.log("from changepaswrd");
  console.log(team);
  return team.map((u) =>
    u.user_id === userId ? { ...u, password: newPassword } : u
  );
};

// Function to toggle a user's status
export const toggleUserStatus = (team, userId) => {
  console.log("from update status");
  console.log(team);
  return team.map((u) =>
    u.user_id === userId ? { ...u, status: !u.status } : u
  );
};

// Function to add a new member to the backend
// export const addMember = async (team, newUser) => {
//   console.log(newUser);
//   try {
//     const response = await axios.post(`${API_URL}/users`, newUser);
//     console.log("User added successfully:", response.data);
//     return [...team, response.data];
//   } catch (error) {
//     console.error("Error adding user: from frontend", error);
//     throw error;
//   }
// };
export const addMember = async (team, memberData) => {
  console.log(memberData);
  try {
    // Make a POST request to the backend to add the new user
    const response = await axios.post("https://backend.promptitude.deloai.com/users", {
      name: memberData.name,
      role: memberData.role,
      phone_number: memberData.phone_number,
      email_id: memberData.email_id,
      alternate_pho_no: memberData.alternate_pho_no,
      reporting_to: memberData.reporting_to,
      password: memberData.password, // This will be "default_password" by default
    });

    // Get the new member data from the response
    const newMember = response.data.data;

    // Return the updated team array with the new member added
    return [...team, newMember];
  } catch (error) {
    console.error("Error adding member: from front", error);
    throw new Error("Failed to add the member. Please try again.");
  }
};
