import React, { useState, useEffect } from "react";
import "./Members.css";
import { FaSearch, FaEllipsisV, FaTimes } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";

import {
  updateUser,
  changePassword,
  toggleUserStatus,
  fetchTeamMembers,
} from "../../Drafts/User";
// const userId = "66ea9b7c24dc0ad011091e5c";
const role = "Admin";
const Members = () => {
  const [team, setTeam] = useState([]); // Initialize as empty array
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newUser, setNewUser] = useState({
    name: "",
    role: "Admin",
    phone_number: "",
    email_id: "",
    alternate_pho_no: "",
    reporting_to: "",
    tasks: [],
    clients: []
  });
  const [error, setError] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isViewMoreModalOpen, setViewMoreModalOpen] = useState(false);
  const [viewMoreUser, setViewMoreUser] = useState(null);
  const user = JSON.parse(sessionStorage.getItem('user'));


  const [password,setPassword]  = useState('')

  console.log(team);
  const filteredTeam = Array.isArray(team)
    ? team.filter(
        (member) =>
          member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          member.role.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  // console.log()

  const handleViewMore = (user) => {
    setViewMoreUser(user);
    setViewMoreModalOpen(true);
    setUpdateModalOpen(false);
    setPasswordModalOpen(false);
    setOpenDropdown(null)

  };

  const handleUpdate = (user) => {
    const reporting_to_id = user.reporting_to ? user.reporting_to.id : ""; 
    setSelectedUser({ ...user, reporting_to: reporting_to_id });
    // setSelectedUser(user);
    setUpdateModalOpen(true);
    setViewMoreModalOpen(false);
    setPasswordModalOpen(false);
    setOpenDropdown(null)

  };

  const handlePasswordChange = (user) => {
    setSelectedUser(user);
    setPasswordModalOpen(true);
    setViewMoreModalOpen(false);
    setUpdateModalOpen(false);
    setOpenDropdown(null)

  };
  const handleUpdatePasswordChange = async (user) => {
    if (!newPassword || !confirmPassword) {
      setError("Password fields cannot be blank.*");
      return;
    }
    console.log(user)
    console.log(newPassword)
    // Check if user object and new password are provided
    if (!user || !newPassword) {
      setError("User object or new password is missing");

      console.error("User object or new password is missing");
      return;
    }
  
    // const { id, newPassword } = user;
    if (newPassword === confirmPassword) {
    try {
      // Send PUT request to update the password
      const response = await fetch(`https://backend.promptitude.deloai.com/user/${user.id}/update_password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ new_password: newPassword }),
      });    
  
      // Parse the response
      const data = await response.json();
  
      // Check if the request was successful
      if (response.ok) {
        alert("Password updated successfully");
        console.log("Password updated:", data);
      } else {
        alert("Failed to update password: " + data.error);
        console.error("Error updating password:", data.error);
      }
    } catch (error) {
      // Handle any other errors
      alert("An error occurred while updating the password");
      console.error("Error:", error);
    }
  } else {
    setError("Passwords do not match.*");
  }
  };
  const handleToggleStatus = (user) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${
        user.status ? "disable" : "enable"
      } this user?`
    );
    if (confirmed) {
      const updatedTeam = toggleUserStatus(team, user.user_id);
      setTeam(updatedTeam);
    }
  };


const handleUpdateUserStatus = async (user) => {
  // Confirm the action with the user before proceeding
  const confirmMessage = `Are you sure you want to ${user.status? 'activate' : 'deactivate'} this user?`;
  const userConfirmed = window.confirm(confirmMessage);

  if (!userConfirmed) {
    // If the user clicks "Cancel", exit the function
    return;
  }

  try {
    // Create the payload with the new status
    const payload = { status: !user.status};
    console.log(payload)

    // Make a PUT request to update the user's status
    const response = await axios.put(`https://backend.promptitude.deloai.com/user/${user.id}/status`, payload);

    // Handle success response
    if (response.status === 200) {
      console.log('User status updated successfully:', response.data);
      // Update the UI or notify the user
      alert(`User status updated successfully to ${!user.status? 'Active' : 'Inactive'}.`);
    }
  } catch (error) {
    // Handle any errors
    console.error('Error updating user status:', error);
    alert('Failed to update user status.');
  }
  loadTeamMembers()
};


  const handleUpdateUser = async(updatedUser) => {

    try{
      // console.log(updateUser)
      if(!updatedUser.reporting_to ){
        alert('Please select Reporting to')
        return 
      }
      const newMemberData = {
        name: updatedUser.name,
        role: updatedUser.role,
        alt_ph_no:updatedUser.alt_ph_no,
        ph_no:updatedUser.ph_no,
        email_id:updatedUser.email_id,
        // ph_no: newUser.phone_number,
        // email_id: newUser.email_id,
        // alternate_pho_no: newUser.alternate_pho_no || "", // Optional field
        // status: true, // Default to active
        reporting_to: updatedUser.reporting_to, // Can be null if not specified
        // password: password, // Placeholder password
      };
      const response = await axios.put(`https://backend.promptitude.deloai.com/user/user/${updatedUser.id}`, newMemberData);
        console.log(response);
        loadTeamMembers();
  
      setUpdateModalOpen(false);
    }

    catch (error) {
      // Handle error and display an alert with the error message
      console.error("Errorupdatingmember:", error);
      
      // Show a user-friendly error message in an alert
      alert(`Failed to update member: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleChangePassword = () => {
    if (!newPassword || !confirmPassword) {
      setError("Password fields cannot be blank.*");
      return;
    }
    if (newPassword === confirmPassword) {
      const updatedTeam = changePassword(
        team,
        selectedUser.user_id,
        newPassword
      );
      setTeam(updatedTeam);
      setPasswordModalOpen(false);
      setError("");
      alert("Password updated successfully!");
    } else {
      setError("Passwords do not match.*");
    }
  };
 
  const loadTeamMembers = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }
      const response = await axios.get(`https://backend.promptitude.deloai.com/user/users?user_id=${userId}`);
      setTeam(response.data);
    } catch (error) {
      console.error("Failed to load team members", error);
    }
  };
  
  useEffect(() => {
    // generatePassword()
    loadTeamMembers();
  }, []);
  //userId, role
  // const handleAddMember = async() => {
  //   // Construct the member data object with proper field alignment
  //   const memberData = {
  //     name: newUser.name,
  //     role: newUser.role,
  //     ph_no: newUser.phone_number,
  //     email_id: newUser.email_id,
  //     alternate_pho_no: newUser.alternate_pho_no || "", // Optional field
  //     status: true, // Default to active
  //     reporting_to: newUser.reporting_to, // Can be null if not specified
  //     password: password, // Placeholder password
  //   };
  //   console.log(memberData)

  //   // Update the team state by adding the new member
  //   const response = await axios.post('https://backend.promptitude.deloai.com/user/add_user', memberData);
  //   console.log(response)
  //   // setTeam(updatedTeam);
  //   // loadTeamMembers()

  //   // // Close the modal and reset the newUser state to default empty values
  //   // setAddModalOpen(false);
  //   // setNewUser({
  //   //   name: "",
  //   //   role: "",
  //   //   phone_number: "",
  //   //   email_id: "",
  //   //   alternate_pho_no: "",
  //   //   reporting_to: "",
  //   //   tasks: [],
  //   //   clients: [],
  //   // });
  //   // generatePassword()
  // };
  // const handleAddMember = async () => {
  //   try {
  //     // Construct the member data object with proper field alignment
  //     const memberData = {
  //       name: newUser.name,
  //       role: user?.role === 'Member' ? 'Member' :newUser.role,
  //       ph_no: newUser.phone_number,
  //       email_id: newUser.email_id,
  //       alt_ph_no: newUser.alternate_pho_no || "", // Optional field
  //       status: true, // Default to active
  //       reporting_to: user?.role === 'Member' ? user.id : newUser.reporting_to,
  //       password: password, // Placeholder password
  //     };
  //     console.log(memberData);
  
  //     // Make the POST request to the server to add a new user
  //     const response = await axios.post('https://backend.promptitude.deloai.com/user/add_user', memberData);
  //     console.log(response);
  
  //     // Perform actions on success (e.g., update team state, close modal, reset form)
  //     // setTeam(updatedTeam);
  //     loadTeamMembers();
      
  //     // Close the modal and reset newUser state to default empty values
  //     setAddModalOpen(false);
  //     setNewUser({
  //       name: "",
  //       role: "Admin",
  //       phone_number: "",
  //       email_id: "",
  //       alternate_pho_no: "",
  //       reporting_to: "",
  //       tasks: [],
  //       clients: [],
  //     });
  
  //     // // Optionally, you can generate a new password
  //     generatePassword();
  //     alert(`User Added Succesfully`);

  
  //   } catch (error) {
  //     // Handle error and display an alert with the error message
  //     console.error("Error adding member:", error);
      
  //     // Show a user-friendly error message in an alert
  //     alert(`Failed to add member: ${error.response?.data?.message || error.message}`);
  //   }
  // };
  const handleAddMember = async () => {
    // Check if mandatory fields are filpipled
    if (!newUser.name || !newUser.email_id || !newUser.phone_number || !password) {
      alert('Please fill all the mandatory fields: Name, Email, Phone Number, and Password.');
      return; // Exit the function if validation fails
    }
    if (newUser.role === 'Member' && !newUser.reporting_to) {
      alert('Please select a Reporting Person for the member.');
      return; // Exit the function if validation fails
    }
    try {
      // Construct the member data object with proper field alignment
      const memberData = {
        name: newUser.name,
        role: user?.role === 'Member' ? 'Member' : newUser.role,
        ph_no: newUser.phone_number,
        email_id: newUser.email_id,
        alt_ph_no: newUser.alternate_pho_no || "", // Optional field
        status: true, // Default to active
        reporting_to: user?.role === 'Member' ? user.id : newUser.reporting_to,
        password: password, // Placeholder password
      };
      console.log(memberData);
  
      // Make the POST request to the server to add a new user
      const response = await axios.post('https://backend.promptitude.deloai.com/user/add_user', memberData);
      console.log(response);
  
      // Perform actions on success (e.g., update team state, close modal, reset form)
      loadTeamMembers();
  
      // Close the modal and reset newUser state to default empty values
      setAddModalOpen(false);
      setNewUser({
        name: "",
        role: "Admin",
        phone_number: "",
        email_id: "",
        alternate_pho_no: "",
        reporting_to: "",
        tasks: [],
        clients: [],
      });
  
      // Optionally, you can generate a new password
      // generatePassword();
      alert(`User Added Successfully`);
  
    } catch (error) {
      // Handle error and display an alert with the error message
      console.error("Error adding member:", error);
  
      // Show a user-friendly error message in an alert
      alert(`Failed to add member: ${error.response?.data?.message || error.message}`);
    }
  };
   
  const handleAddButtonClick = () => {
    setAddModalOpen(true);
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  console.log();
  const userOptions = Array.isArray(team)
    ? team.map((user) => (
        <option key={user.user_id} value={user.id}>
          {user.name}
        </option>
      ))
    : [];
  console.log("i am useOptions", userOptions);
  return (
    <div className="members-container">
      <h1 className="page-heading">Team Members</h1>

      {/* Search Bar */}
      <div className="search-bar-container">
        <div className="input-wrapper">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search by name or role..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button className="add-member-btn" onClick={handleAddButtonClick}>
          Add Member
        </button>
      </div>

      <table className="team-table">
        <thead>
          <tr>
            <th> Name</th>
            <th>Role</th>
            <th>Clients</th>
            <th>Tasks</th>
            <th>Reporting To</th>
            <th>Status</th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTeam.map((member, index) => (
            <tr key={index}>
  <td style={styles.td}>
        {/* Use Link to make the name clickable with improved styling */}
        <Link 
          to={`/user/${member.id}`} 
          style={styles.link}
        >
          {member.name}
        </Link>
      </td>              <td>{member.role}</td>
             
              {/* <td>{member.status ? "Active" : "Inactive"}</td> */}
              <td>{member.clients.length}</td>
              <td>{member.tasks.length}</td>
              <td>{member.reporting_to ? member.reporting_to.name: 'NA'}</td>
              <td >
                      <button
                        className={`toggle-btn ${member.status ? "toggled" : ""}` }
                        onClick={() => handleUpdateUserStatus(member)}
                      >
                        <div className="thumb"></div>
                      </button>
                    </td>
              <td>
                <div
                  className={`dropdown ${
                    openDropdown === index ? "active" : ""
                  }`}
                >
                  <button
                    className="kebab-menu"
                    onClick={() => toggleDropdown(index)}
                  >
                    {openDropdown === index ? <FaTimes /> : <FaEllipsisV />}
                  </button>

                  <ul className="dropdown-menu">
                    <li onClick={() => handleViewMore(member)}>View More</li>
                    <li onClick={() => handleUpdate(member)}>Update</li>
                    <li onClick={() => handlePasswordChange(member)}>
                      Change Password
                    </li>
                  

                  </ul>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isUpdateModalOpen && (
        <div className="modal">
          <h2>Update User</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateUser(selectedUser);
            }}
          >
            <label>Name: </label>
            <input
              type="text"
              name="name"
              value={selectedUser.name}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, name: e.target.value })
              }
            />
            {/* <br /> */}
            <label>Role: </label>
            <select
              name="role"
              value={selectedUser.role}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, role: e.target.value })
              }
            >
              <option value="Admin">Admin</option>
              <option value="Member">Member</option>
            </select>
            {/* <br /> */}
            <label>Email ID: </label>
            <input
              type="email"
              value={selectedUser.email_id}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, email_id: e.target.value })
              }
            />

            <div className="row-fields">
              <div>
                <label>Phone Number: </label>
                <input
                  type="text"
                  value={selectedUser.ph_no}
                  onChange={(e) =>
                    setSelectedUser({...selectedUser, ph_no: e.target.value })
                  }
                />
              </div>
              {/* <br /> */}
              <div>
                <label>Alternate Phone Number: </label>
                <input
                  type="text"
                  value={selectedUser.alt_ph_no}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, alt_ph_no: e.target.value })
                  }
                />
              </div>
            </div>
            <label>Reporting To: </label>
            <select
              value={selectedUser.reporting_to || ""}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  reporting_to: e.target.value,
                })
              }
            >
              <option value="">Select Reporting Person</option>
              {userOptions}
            </select>
            {/* <br /> */}
            <div className="button-container">
              <button className="update-btn" type="submit">
                Update
              </button>
              <button
                className="close-btn"
                type="button"
                onClick={() => setUpdateModalOpen(false)}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      )}

      {isPasswordModalOpen && (
        <div className="modal">
          <h2>Change Password</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdatePasswordChange(selectedUser);
            }}
          >
            <label>New Password: </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {/* <br /> */}
            <label>Confirm Password: </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {/* <br /> */}
            {error && <p className="error-message">{error}</p>}
            <div className="button-container">
              <button className="update-btn" type="submit">
                Change Password
              </button>
              <button
                className="close-btn"
                type="button"
                onClick={() => setPasswordModalOpen(false)}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      )}

      {isAddModalOpen && (
        <div className="modal">
          <h2>Add New Member</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddMember();
            }}
          >
            <label>Name: </label>
            <input
              type="text"
              value={newUser.name}
              onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            />
            {/* <br /> */}
            {user.role ==='Admin' && (
       <>
       <label>Role: </label>
            <select
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
              className="styled-select"
            >
              <option value="Admin">Admin</option>
              <option value="Member">Member</option>
            </select>
            </>
            )}
            {/* <br /> */}
            <label>Email ID: </label>
            <input
              type="email"
              value={newUser.email_id}
              onChange={(e) =>
                setNewUser({ ...newUser, email_id: e.target.value })
              }
            />
            <label>Password: </label>
      <input
        type="password" // Set the input type to password for manual entry
        value={password}
        onChange={(e) => setPassword(e.target.value)} // Store the input password in state
      />
            {/* <br /> */}
            <div className="row-fields">
              <div>
                <label>Phone Number: </label>
                <input
                  type="text"
                  value={newUser.phone_number}
                  onChange={(e) =>
                    setNewUser({ ...newUser, phone_number: e.target.value })
                  }
                />
              </div>
              {/* <br /> */}
              <div>
                <label>Alternate Phone Number: </label>
                <input
                  type="text"
                  value={newUser.alternate_pho_no}
                  onChange={(e) =>
                    setNewUser({ ...newUser, alternate_pho_no: e.target.value })
                  }
                />
              </div>
            </div>
            {/* <br /> */}
            {/* <label>Reporting To: </label>
            <select
              value={newUser.reporting_to}
              onChange={(e) => {
                console.log(e.target.value);
                setNewUser({ ...newUser, reporting_to: e.target.value });
              }}
            >
              <option value="">Select Reporting Person</option>
              {userOptions}
            </select> */}

{user.role === 'Admin' && (
              <>
                <label>Reporting To: </label>
                <select value={newUser.reporting_to} onChange={(e) => setNewUser({ ...newUser, reporting_to: e.target.value })}>
                  <option value="">Select Reporting Person</option>
                  {userOptions}
                </select>
              </>
            )}
            {/* <br /> */}
            <div className="button-container">
              <button className="update-btn" type="submit">
                Add Member
              </button>
              <button
                className="close-btn"
                type="button"
                onClick={() => setAddModalOpen(false)}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      )}

      {isViewMoreModalOpen && viewMoreUser && (
        <div className="modal">
          <h2>User Details</h2>
          <p>
            <strong>Name:</strong> {viewMoreUser.name}
          </p>
          <p>
            <strong>Role:</strong> {viewMoreUser.role}
          </p>
          <p>
            <strong>Phone Number:</strong> {viewMoreUser.ph_no}
          </p>
          <p>
            <strong>Email ID:</strong> {viewMoreUser.email_id}
          </p>
          <p>
            <strong>Alternate Phone Number:</strong>{" "}
            {viewMoreUser.alt_ph_no}
          </p>
          <p>
            <strong>Clients:</strong> {viewMoreUser.clients.length}
          </p>
          <p>
            <strong>Reporting To:</strong> {viewMoreUser.reporting_to ? viewMoreUser.reporting_to.name : 'NA'}
          </p>
          <p>
            <strong>Tasks:</strong> {viewMoreUser.tasks.length}
          </p>
          <button
            className="close-btn"
            onClick={() => setViewMoreModalOpen(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};
const styles = {
  link: {
    textDecoration: 'none',
    color: '#666666', // Gives it a blue color similar to hyperlinks
    cursor: 'pointer', // Shows the pointer to indicate it's clickable
    transition: 'color 0.3s', // Smooth transition for the color on hover
  },
}
export default Members;
