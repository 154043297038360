import React, { useState, useEffect } from "react";
import { FaArrowRight, FaTasks, FaUserAlt, FaUserClock, FaCalendarAlt } from "react-icons/fa";
import axios from "axios";

const Dashboard = () => {
  const [clientsCount, setClientsCount] = useState(0);
  const [tasksCount, setTasksCount] = useState(0);
  const [pendingLeaves, setPendingLeaves] = useState(0);

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    loadClients();
    loadTasks();
    loadPendingLeaves();
  }, []);

  const loadClients = async () => {
    try {
      const response = await axios.get("https://backend.promptitude.deloai.com/clients");
      setClientsCount(response.data.length);
    } catch (error) {
      console.error("Failed to load clients", error);
    }
  };

  const loadTasks = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }
      const response = await axios.get(`https://backend.promptitude.deloai.com/tasks/assigned_due_today`, {
        params: {
          user_id: userId,
        },
      });
      setTasksCount(response.data.length);
    } catch (error) {
      console.error("Failed to load tasks", error);
    }
  };

  const loadPendingLeaves = async () => {
    const userId = user?.id;

    try {
      const response = await axios.get("https://backend.promptitude.deloai.com/leaves/admin_or_reported",{
        params: { user_id: userId },

      });
      setPendingLeaves(response.data.length);
    } catch (error) {
      console.error("Failed to load pending leaves", error);
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Dashboard</h1>
      <div style={styles.dashboardGrid}>
        {/* Clients Card */}
        <div style={styles.card}>
          <div style={styles.cardContent}>
            <FaUserAlt style={styles.icon} />
            <h2>{clientsCount}</h2>
            <p>Clients</p>
          </div>
          <div style={styles.cardAction}>
            <a href="/clients" style={styles.arrowLink}>
              View Clients <FaArrowRight />
            </a>
          </div>
        </div>

        {/* Tasks Card */}
        <div style={styles.card}>
          <div style={styles.cardContent}>
            <FaTasks style={styles.icon} />
            <h2>{tasksCount}</h2>
            <p>Tasks - Due Today</p>
          </div>
          <div style={styles.cardAction}>
            <a href="/tasks/today" style={styles.arrowLink}>
              View Tasks <FaArrowRight />
            </a>
          </div>
        </div>

        {/* Pending Leaves Card */}
        <div style={styles.card}>
          <div style={styles.cardContent}>
            <FaUserClock style={styles.icon} />
            <h2>{pendingLeaves}</h2>
            <p>Pending Leaves</p>
          </div>
          <div style={styles.cardAction}>
            <a href="/my-teams/leaves" style={styles.arrowLink}>
              View Leaves <FaArrowRight />
            </a>
          </div>
        </div>

        {/* Compliance Card */}
        <div style={styles.card}>
          <div style={styles.cardContent}>
            <FaCalendarAlt style={styles.icon} />
            <h2>Compliance</h2>
            <p>View compliance reports</p>
          </div>
          <div style={styles.complianceLinks}>
            <a href="/monthly/tds-payment" style={styles.complianceLink}>
              Monthly
            </a>
            <a href="/quarterly/tds-returns" style={styles.complianceLink}>
              Quarterly
            </a>
            <a  href="/yearly/pt-payment" style={styles.complianceLink}>
              Yearly
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#ffffff", // Main background remains white
    color: "#333", // Dark text for contrast
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Lighter shadow for soft depth
  },
  header: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "30px",
    color: "#333", // Dark text for the title
  },
  dashboardGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
  },
  card: {
    backgroundColor: "#f5f5f5", // Lighter card background to blend with the white theme
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow for the card
    transition: "transform 0.3s ease", // Smooth transition effect
    textAlign: "center",
    position: "relative",
    color: "#333", // Dark text for contrast
  },
  cardContent: {
    marginBottom: "10px",
  },
  icon: {
    fontSize: "40px",
    marginBottom: "10px",
    color: "#3bc9db", // Teal color for icons
  },
  cardAction: {
    marginTop: "10px",
  },
  arrowLink: {
    fontSize: "16px",
    textDecoration: "none",
    color: "#3bc9db", // Teal color for links
    display: "inline-flex",
    alignItems: "center",
    fontWeight: "bold",
    transition: "color 0.3s",
  },
  complianceLinks: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
  },
  complianceLink: {
    padding: "8px 16px",
    backgroundColor: "#3bc9db", // Button teal background
    color: "#fff", // White text for contrast on buttons
    borderRadius: "8px",
    textDecoration: "none",
    transition: "background-color 0.3s ease",
    fontWeight: "bold",
  },
};

export default Dashboard;
