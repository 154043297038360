import React, { useState, useEffect } from "react";
import axios from "axios";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user")); // Assuming user info is stored in sessionStorage

  useEffect(() => {
    if (user?.id) {
      loadNotifications();
    }
  }, [user]);

  const loadNotifications = async () => {
    try {
      const userId = user?.id;
      if (!userId) {
        console.error("User ID not found in session");
        return;
      }

      // Fetch notifications for the user from the server
      const response = await axios.get("https://backend.promptitude.deloai.com/notifications", {
        params: { user_id: userId },
      });

      const fetchedNotifications = response.data;

      // Automatically mark notifications as read if they are not already read
      const unreadNotifications = fetchedNotifications.filter(notification => !notification.is_read);
      unreadNotifications.forEach(notification => {
        markAsRead(notification.id); // Mark unread notifications as read
      });

      setNotifications(fetchedNotifications); // Set the fetched notifications
    } catch (error) {
      console.error("Failed to load notifications", error);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      // Mark a notification as read by sending a PATCH request
      await axios.patch(`https://backend.promptitude.deloai.com/notifications/${notificationId}/read`);
    } catch (error) {
      console.error(`Failed to mark notification ${notificationId} as read`, error);
    }
  };

  return (
    <div style={styles.container}>
      <h1>Notifications</h1>
      {notifications.length > 0 ? (
        <ul style={styles.notificationList}>
          {notifications.map((notification) => (
            <li
              key={notification.id}
              style={{
                ...styles.notificationItem,
                backgroundColor: notification.is_read ? "#f0f0f0" : "#e0e0ff", // Unread notifications highlighted
              }}
            >
              <div>
                <strong>{notification.title}</strong> {/* Notification title */}
                <p>{notification.message}</p> {/* Notification message */}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No notifications available</p>
      )}
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: "20px",
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
  notificationList: {
    listStyle: "none",
    padding: 0,
  },
  notificationItem: {
    padding: "15px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #ccc",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

export default Notifications;
